@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900");



/*--------------------------- Color variations ----------------------*/
/* Medium Layout: 1280px */
/* Tablet Layout: 768px */
/* Mobile Layout: 320px */
/* Wide Mobile Layout: 480px */
/* =================================== */
/*  Basic Style 
/* =================================== */
::-moz-selection {
  /* Code for Firefox */
  background-color: #46dbf9;
  color: #fff;
}

::selection {
  background-color: #46dbf9;
  color: #fff;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #777777;
  font-weight: 300;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #777777;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #777777;
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #777777;
  font-weight: 300;
}

body {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.625em;
  position: relative;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

select {
  display: block;
}

figure {
  margin: 0;
}

a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  text-decoration: none;
  outline: 0;
}

.card-panel {
  margin: 0;
  padding: 60px;
}

/**
 *  Typography
 *
 **/
.btn i,
.btn-large i,
.btn-floating i,
.btn-large i,
.btn-flat i {
  font-size: 1em;
  line-height: inherit;
}

.gray-bg {
  background: #f9f9ff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #222222;
  line-height: 1.2em !important;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #222222;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 16px;
}

h6,
.h6 {
  font-size: 14px;
  color: #222222;
}

td,
th {
  border-radius: 0px;
}

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clear::before,
.clear::after {
  content: " ";
  display: table;
}

.clear::after {
  clear: both;
}

.fz-11 {
  font-size: 11px;
}

.fz-12 {
  font-size: 12px;
}

.fz-13 {
  font-size: 13px;
}

.fz-14 {
  font-size: 14px;
}

.fz-15 {
  font-size: 15px;
}

.fz-16 {
  font-size: 16px;
}

.fz-18 {
  font-size: 18px;
}

.fz-30 {
  font-size: 30px;
}

.fz-48 {
  font-size: 48px !important;
}

.fw100 {
  font-weight: 100;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500;
}

.f700 {
  font-weight: 700;
}

.fsi {
  font-style: italic;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-150 {
  margin-top: 150px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-0-i {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-170 {
  padding-top: 170px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-90 {
  padding-left: 90px;
}

.p-40 {
  padding: 40px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: opacity 0.3s linear;
}

.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.section-full {
  padding: 100px 0;
}

.section-half {
  padding: 75px 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-rigth {
  text-align: right;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.flex-grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.flex-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-top {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}

.flex-bottom {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}

.flex-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.display-table {
  display: table;
}

.light {
  color: #fff;
}

.dark {
  color: #000;
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.container.fullwidth {
  width: 100%;
}

.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-padding {
  padding: 0;
}

.section-bg {
  background: #f9fafc;
}

@media (max-width: 767px) {
  .no-flex-xs {
    display: block !important;
  }
}

.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}

.section-gap {
  padding: 150px 0;
}

@media (max-width: 991px) {
  .section-gap {
    padding: 70px 0;
  }
}

.section-gap-top {
  padding-top: 80px;
}

@media (max-width: 991px) {
  .section-gap-top {
    padding-top: 70px;
  }
}

.section-gap-bottom {
  padding-bottom: 150px;
}

@media (max-width: 991px) {
  .section-gap-bottom {
    padding-bottom: 70px;
  }
}

.margin-gap-bottom {
  margin-bottom: 150px;
}

@media (max-width: 991px) {
  .margin-gap-bottom {
    margin-bottom: 70px;
  }
}

.section-title {
  margin-bottom: 80px;
}

@media (max-width: 991px) {
  .section-title {
    margin-bottom: 40px;
  }
}

.section-title h2 {
  margin-bottom: 20px;
  font-size: 36px;
}

@media (max-width: 413px) {
  .section-title h2 {
    font-size: 30px;
  }
}

.section-title p {
  font-size: 16px;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .section-title p br {
    display: none;
  }
}

.alert-msg {
  color: #008000;
}

.p1-gradient-bg,
.buttons.gray:hover,
.primary-btn,
.feature-area .single-feature .title .lnr,
.testimonials-area .owl-thumb-item.active .overlay-grad,
.testimonials-area .owl-thumb-item .overlay-grad:hover,
.single-price:hover .price-btn,
.top-category-widget-area .single-cat-widget:hover .overlay-bg,
.post-content-area .single-post .primary-btn:hover,
.blog-pagination .page-item.active .page-link,
.blog-pagination .page-link:hover,
.widget-wrap .newsletter-widget .bbtns,
.footer-area .single-footer-widget .click-btn,
.footer-area .footer-bottom .footer-social a:hover {
  background-image: -moz-linear-gradient(0deg, #46dbf9 0%, #204be3 100%);
  background-image: -webkit-linear-gradient(0deg, #46dbf9 0%, #204be3 100%);
  background-image: -ms-linear-gradient(0deg, #46dbf9 0%, #204be3 100%);
  background-image: -o-linear-gradient(0deg, #46dbf9 0%, #204be3 100%);
}

.p1-gradient-color {
  background: -moz-linear-gradient(0deg, #46dbf9 0%, #204be3 100%);
  background: -webkit-linear-gradient(0deg, #46dbf9 0%, #204be3 100%);
  background: -ms-linear-gradient(0deg, #46dbf9 0%, #204be3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 991px) {
  .download-button {
    -webkit-box-direction: normal !important;
    -webkit-box-orient: vertical !important;
    -webkit-flex-direction: column !important;
    -moz-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
}

.buttons {
  padding: 20px 24px 8px;
  max-width: 225px;
  margin-right: 10px;
  border: 1px solid #3d65a9;
  border-radius: 4px;
  -webkit-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  background-color: rgba(132, 173, 249, 0.48);
  color: #000!important;
}

@media (max-width: 991px) {
  .buttons:first-child {
    margin-bottom: 16px;
  }
}

.buttons i {
  font-size: 44px;
  padding-right: 20px;
  color: #38a4ff;
  margin-top: 8px;
  margin-left: 15px;
}

.buttons:hover {
  cursor: pointer;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  background-color: rgba(70, 219, 249, 0.4);
  color: #fff !important;
  border: 1px solid transparent;
}

.buttons:hover a {
  color: #fff;
}

.buttons a {
  color: #e4e4e4;
}

.buttons p {
  margin-bottom: 8px !important;
  font-size: 14px;
  line-height: 26px;
  text-align: left;
}

.buttons p span {
  font-size: 24px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.buttons.dark {
  background-color: #000;
  color: #fff !important;
  border: 1px solid #000;
}

.buttons.dark i {
  color: #fff;
}

.buttons.dark a {
  color: #fff;
}

.buttons.dark:hover {
  background-color: #46dbf9;
  border: 1px solid transparent;
}

.buttons.gray {
  background-color: #393939;
  color: #fff !important;
  border: 1px solid #393939;
}

.buttons.gray i {
  color: #fff;
}

.buttons.gray a {
  color: #fff;
}

.buttons.gray:hover {
  border: 1px solid transparent;
}

.primary-btn {
  height: 50px;
  line-height: 50px;
  padding-left: 45px;
  padding-right: 45px;
  border-radius: 0px;
  border: 1px solid transparent;
  background-origin: border-box;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  position: relative;
}

.primary-btn:focus {
  outline: none;
}

.primary-btn:hover {
  background: transparent;
  color: #46dbf9;
  border-color: #46dbf9;
}

.primary-btn.white {
  border: 1px solid #fff;
  color: #fff;
}

.primary-btn.white:hover {
  background: #fff;
  color: #46dbf9;
}

.white_bg_btn {
  display: inline-block;
  background: #f9f9ff;
  padding: 0px 35px;
  color: #222222;
  font-size: 13px;
  font-weight: 400;
  line-height: 34px;
  border-radius: 0px;
  outline: none !important;
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #eeeeee;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.white_bg_btn:hover {
  background: #46dbf9;
  color: #fff;
  border: 1px solid #46dbf9;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0;
}

.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

b,
sup,
sub,
u,
del {
  color: #46dbf9;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5em;
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #777777;
}

.button-area {
  background: #fff;
}

.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.genric-btn:focus {
  outline: none;
}

.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

.genric-btn.large {
  line-height: 45px;
}

.genric-btn.medium {
  line-height: 30px;
}

.genric-btn.small {
  line-height: 25px;
}

.genric-btn.radius {
  border-radius: 3px;
}

.genric-btn.circle {
  border-radius: 20px;
}

.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.genric-btn.arrow span {
  margin-left: 10px;
}

.genric-btn.default {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.default-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.primary {
  color: #fff;
  background: #46dbf9;
  border: 1px solid transparent;
}

.genric-btn.primary:hover {
  color: #46dbf9;
  border: 1px solid #46dbf9;
  background: #fff;
}

.genric-btn.primary-border {
  color: #46dbf9;
  border: 1px solid #46dbf9;
  background: #fff;
}

.genric-btn.primary-border:hover {
  color: #fff;
  background: #46dbf9;
  border: 1px solid transparent;
}

.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

.genric-btn.link {
  color: #222222;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

.genric-btn.link:hover {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
}

.genric-btn.link-border {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

.genric-btn.link-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent;
}

.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #46dbf9;
}

.progress-table-wrap {
  overflow-x: scroll;
}

.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

.progress-table .country {
  width: 28.07%;
}

.progress-table .visit {
  width: 19.74%;
}

.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

.progress-table .table-head {
  display: flex;
}

.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #222222;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

.progress-table .table-row .country img {
  margin-right: 15px;
}

.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
}

.list-style {
  width: 14px;
  height: 14px;
}

.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #46dbf9;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

.ordered-list {
  margin-left: 30px;
}

.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #46dbf9;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #46dbf9;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-alpha li span {
  font-weight: 300;
  color: #777777;
}

.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #46dbf9;
  font-weight: 500;
  line-height: 1.82em !important;
}

.ordered-list-roman li span {
  font-weight: 300;
  color: #777777;
}

.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input:focus {
  outline: none;
}

.input-group-icon {
  position: relative;
}

.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

.input-group-icon .icon i {
  color: #797979;
}

.input-group-icon .single-input {
  padding-left: 45px;
}

.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

.single-textarea:focus {
  outline: none;
}

.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-primary:focus {
  outline: none;
  border: 1px solid #46dbf9;
}

.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.default-switch input+label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #46dbf9;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.default-switch input:checked+label {
  left: 19px;
}

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-switch input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.primary-switch input+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.primary-switch input+label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.primary-switch input:checked+label:after {
  left: 19px;
}

.primary-switch input:checked+label:before {
  background: #46dbf9;
}

.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-switch input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.confirm-switch input+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.confirm-switch input+label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.confirm-switch input:checked+label:after {
  left: 19px;
}

.confirm-switch input:checked+label:before {
  background: #4cd3e3;
}

.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-checkbox input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-checkbox input:checked+label {
  /*background: url(../img/elements/primary-check.png) no-repeat center center/cover;*/
  border: none;
}

.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-checkbox input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-checkbox input:checked+label {
  /*background: url(../img/elements/success-check.png) no-repeat center center/cover;*/
  border: none;
}

.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-checkbox input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-checkbox input:checked+label {
  /*background: url(../img/elements/disabled-check.png) no-repeat center center/cover;*/
  border: none;
}

.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.primary-radio input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.primary-radio input:checked+label {
  /*background: url(../img/elements/primary-radio.png) no-repeat center center/cover;*/
  border: none;
}

.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.confirm-radio input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.confirm-radio input:checked+label {
 /* background: url(../img/elements/success-radio.png) no-repeat center center/cover;*/
  border: none;
}

.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.disabled-radio input+label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

.disabled-radio input:checked+label {
  /*background: url(../img/elements/disabled-radio.png) no-repeat center center/cover;*/
  border: none;
}

.default-select {
  height: 40px;
}

.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}

.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

.default-select .nice-select .list .option.selected {
  color: #46dbf9;
  background: transparent;
}

.default-select .nice-select .list .option:hover {
  color: #46dbf9;
  background: transparent;
}

.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.default-select .nice-select::after {
  right: 20px;
}

.form-select {
  height: 40px;
  width: 100%;
}

.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

.form-select .nice-select .list .option.selected {
  color: #46dbf9;
  background: transparent;
}

.form-select .nice-select .list .option:hover {
  color: #46dbf9;
  background: transparent;
}

.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

.form-select .nice-select::after {
  right: 20px;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.main-menu {
  padding-top: 20px;
}

.main-menu>.row {
  padding: 0 15px;
}

#header {
  padding: 0px 0px 20px 0px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
  background-color: rgba(32, 140, 251, 0.72);
}

.header-scrolled {
  background: rgba(70, 219, 249, 0.9);
  transition: all 0.5s;
}

@media (max-width: 673px) {
  #logo {
    margin-left: 20px;
  }
}

#header #logo h1 {
  font-size: 34px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 3px;
  color: #fff;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
  padding-left: 10px;
  border-left: 4px solid #46dbf9;
}

#logo  {
  padding: 0;
  margin: 0;
  color: #fff;
}

#logo a {
  color: #fff;
  font-weight: 700;
}

@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px;
  }

  #header #logo img {
    max-height: 40px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu>li {
  float: left;
}

.nav-menu li:hover>ul,
.nav-menu li.sfHover>ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
  text-align: left;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  margin: 0;
}

/* Nav Meu Styling */
.nav-menu a {
  padding: 0 18px;
  margin: 0 -2px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  outline: none;
}

.nav-menu a:last-child a {
  margin-right: 0;
  padding-right: 0;
}

.nav-menu ul {
  margin: 0 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

.nav-menu ul li {
  transition: 0.3s;
}

.nav-menu ul li>a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 14px;
  text-transform: none;
}

.nav-menu a:hover {
  cursor: pointer;
  color: #fff;
}

.nav-menu ul li:hover a {
  color: #00183d;
}

.nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 15px;
  z-index: 999;
  top: 22px;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

@media (max-width: 767px) {
  #mobile-nav-toggle {
    top: 22px;
  }
}

#mobile-nav-toggle i {
  color: #fff;
  font-weight: 900;
}

@media (max-width: 960px) {
  #mobile-nav-toggle {
    display: inline;
  }

  #nav-menu-container {
    display: none;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.8);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
  font-weight: 700;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #46dbf9;
}

#mobile-nav ul .menu-has-children li a {
  text-transform: none;
}

#mobile-nav ul .menu-item-active {
  color: #46dbf9;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

/* Mobile Nav body classes */
.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active {
  left: 0 !important;
}

.mobile-nav-active  {
  color: #fff;
}

.iframe {
  width: 100%;
}

.home-banner-area {
  position: relative;
  overflow: hidden;
  background: url(../img/Background-35208.jpg) center;
  background-size: cover;
 padding: 100px 0 70px 0;
}

.video-container {
  position: absolute;
  top:0;
  width: 100%;
}

.home-banner-area-small {
  padding: 20px 0;
}

.home-banner-area .banner-img {
  text-align: right;
}

@media (max-width: 1440px) {
  .home-banner-area .banner-img {
    text-align: left;
  }

  .home-banner-area .banner-img img {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .home-banner-area .banner-img {
    text-align: center;
  }
  .video-container {
    display: none;
  }
}

@media (max-width: 767px) {
  .home-banner-area .banner-img {
    display: none;
  }
}

/*
@media (max-width: 991px) {
  .home-banner-area .fullscreen {
    height: 700px !important;
  }
}
*/

.banner-area {
  background: url(../img/banner.jpg) center;
  background-size: cover;
}

.banner-area .banner-content {
  padding: 162px 0;
}

@media (max-width: 767px) {
  .banner-area .banner-content {
    padding: 150px 0 50px;
  }
}

@media (max-width: 767px) {
  .banner-area .banner-content>.col-lg-12.d-flex {
    display: block !important;
  }
}

.banner-area .banner-content .left-part h1 {
  margin-bottom: 15px;
}

.banner-area .banner-content .left-part p {
  max-width: 510px;
}

@media (max-width: 767px) {
  .banner-area .banner-content .right-part {
    margin-top: 100px;
  }
}

.banner-area .banner-content .right-part a {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

@media (max-width: 767px) {
  .banner-area .banner-content .right-part a {
    color: #222222;
  }
}

.banner-area .banner-content .right-part span {
  margin: 0 10px;
  color: #fff;
  font-size: 14px;
}

@media (max-width: 767px) {
  .banner-area .banner-content .right-part span {
    color: #222222;
  }
}

.home-banner-content h1 {
  color: #bcd3f1;
  font-size: 60px;
  margin-bottom: 25px;
}

.home-banner-area-small h1 {
  margin-top: 60px;
}

.home-banner-content h2{
  color: #ffffff;
  font-size: 40px;
  margin-bottom: 25px;
  padding: 10px;
  text-align: center;
  background-color: #38a4ff;
}

@media (max-width: 991.98px) {
  .home-banner-content h1 {
    font-size: 36px;
  }
}

@media (max-width: 1199.98px) {
  .home-banner-content h1 {
    font-size: 35px;
  }
}

@media (max-width: 767px) {
  .home-banner-content h1 {
    margin-top: 50px;
  }
}

.home-banner-content p {
  margin-bottom: 45px;
  color: #dcf2ef;
  font-weight: 700;
}

.home-banner-area .buttons {
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
  padding: 15px;
}

.about-area {
  position: relative;
  padding: 150px 0 70px;
}

@media (max-width: 991px) {
  .about-area {
    padding: 20px 0 70px;
  }
}

.about-area .container:after {
  content: '';
  position: absolute;
  top: 10%;
  right: 36%;
  width: 68%;
  height: 68%;
  background: linear-gradient(to bottom right, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  background: -webkit-linear-gradient(to bottom right, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  background: -moz-linear-gradient(to bottom right, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  background: -ms-linear-gradient(to bottom right, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  background: -o-linear-gradient(to bottom right, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  z-index: -1;
}

@media (max-width: 991px) {
  .about-area .container:after {
    content: none;
  }
}

.home-about-left img {
  width: 100%;
}

@media (max-width: 991px) {
  .home-about-left img {
    display: none;
  }
}

.home-about-right h1 {
  padding: 20px 0;
}

@media (max-width: 413px) {
  .home-about-right h1 {
    font-size: 30px;
  }

  .home-about-right h1 br {
    display: none;
  }
}

.home-about-right p {
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  .home-about-right2 {
    margin-top: 50px;
  }
}

.about-video-area {
  position: relative;
}

.about-video-area .vdo-bg:before,
.about-video-area .vdo-bg:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
}

.about-video-area .vdo-bg:before {
  left: 0;
  width: 30%;
  background: #f1f9ff;
}

.about-video-area .vdo-bg:after {
  right: 0;
  width: 70%;
  background: #2f2f2f;
}

.about-video-right {
  background: url(../img/video-bg.jpg) no-repeat center;
  background-size: cover;
  height: 645px;
}

.about-video-right .play-btn {
  z-index: 2;
}

.feature-area {
  position: relative;
}

/*
.feature-area:after {
  content: "";
  position: absolute;
  right: 16%;
  bottom: -100px;
  z-index: -1;
  width: 340px;
  height: 340px;
  background: linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -webkit-linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -moz-linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -ms-linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -o-linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
}*/

@media (max-width: 991px) {
  .feature-area:after {
    display: none;
  }
}

.feature-area .single-feature {
  background: #fff;
  padding: 40px 30px;
  text-align: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
  .feature-area .single-feature {
    margin-bottom: 30px;
  }
}

.feature-area .single-feature h3 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-size: 21px;
}

.feature-area .single-feature .title .lnr {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  display: inline-block;
  margin-bottom: 40px;
}

.feature-area .single-feature p {
  margin-bottom: 0px;
  margin-top: 20px;
}

.feature-area .single-feature:hover {
  cursor: pointer;
}

.feature-area .single-feature:hover h3 {
  color: #46dbf9;
}

.feature-area ul {
  margin: 20px 0;
}

.feature-area li {
  margin: 0 0 0 10px;
}

.fact-area .fact-box {
  box-shadow: 0px 20px 50px rgba(153, 153, 153, 0.2);
}

.single-fact {
  text-align: center;
  margin: 45px 0;
  padding: 8px 16px;
  border-right: 1px solid #ddd;
}

@media (max-width: 991px) {
  .single-fact {
    margin: 10px 0;
    padding: 8px 32px;
  }
}

.single-fact:last-child {
  border-right: 0;
}

.single-fact h2 {
  font-weight: 700;
  font-size: 5vh;
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .single-fact h2 {
    font-size: 25px;
  }
}

.single-fact p {
  margin: 0;
  font-size: 14px;
}

.testimonials-area {
  position: relative;
}

.testimonials-area.about-page .container::before {
  display: none;
}

.testimonials-area .container:before {
  content: "";
  position: absolute;
  left: 17%;
  bottom: 0;
  z-index: -1;
  width: 340px;
  height: 340px;
  background: linear-gradient(to bottom left, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -webkit-linear-gradient(to bottom left, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -moz-linear-gradient(to bottom left, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -ms-linear-gradient(to bottom left, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -o-linear-gradient(to bottom left, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
}

@media (max-width: 991px) {
  .testimonials-area .container:before {
    display: none;
  }
}

.testimonials-area .owl-thumb-item {
  margin-right: 20px;
  position: relative;
}

.testimonials-area .owl-thumb-item:last-child {
  margin-right: 0;
}

.testimonials-area .owl-thumb-item.active .overlay-grad {
  opacity: .8;
}

.testimonials-area .owl-thumb-item .overlay-grad {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.testimonials-area .owl-thumb-item .overlay-grad:hover {
  opacity: .8;
}

.testi-slider {
  margin: auto;
}

.testi-slider .testi-item {
  text-align: center;
}

.testi-slider .testi-item img {
  width: auto;
  margin: auto;
}

.testi-item {
  margin-bottom: 50px;
}

.testi-item h4 {
  font-size: 21px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 10px;
}

.testi-item .list {
  margin-bottom: 35px;
}

.testi-item .list li {
  display: inline-block;
}

.testi-item .list li a {
  color: #ffc000;
  font-size: 14px;
}

.testi-item p {
  max-width: 605px;
  margin: auto;
}

.screenshot-area {
  position: relative;
}

.screenshot-area .container:after {
  content: '';
  position: absolute;
  top: 20%;
  left: 31%;
  width: 69%;
  height: 188%;
  background: linear-gradient(to bottom left, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  background: -webkit-linear-gradient(to bottom left, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  background: -moz-linear-gradient(to bottom left, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  background: -ms-linear-gradient(to bottom left, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  background: -o-linear-gradient(to bottom left, transparent 0%, transparent 50%, #f8fcff 50%, #fcfdff 100%);
  z-index: -1;
}

@media (max-width: 991px) {
  .screenshot-area .container:after {
    content: none;
  }
}

.screenshot-area .single-screenshot {
  cursor: pointer;
}

@media (max-width: 991px) {
  .screenshot-area .single-screenshot {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .screenshot-area .single-screenshot {
    padding: 30px;
    text-align: center;
  }
}

.pricing-area {
  padding: 150px 0;
  position: relative;
}

@media (max-width: 991px) {
  .pricing-area {
    padding: 40px 0 70px;
  }
}

.pricing-area.pricing-page {
  padding-bottom: 0;
}

.pricing-area.pricing-page:after {
  display: none;
}

.pricing-area:after {
  content: "";
  position: absolute;
  right: 16%;
  bottom: 70px;
  z-index: -1;
  width: 340px;
  height: 340px;
  background: linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -webkit-linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -moz-linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -ms-linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
  background: -o-linear-gradient(to bottom right, transparent 0%, transparent 50%, #46dbf9 50%, #204be3 100%);
}

@media (max-width: 991px) {
  .pricing-area:after {
    display: none;
  }
}

.single-price {
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}

@media (max-width: 991px) {
  .single-price {
    margin-bottom: 30px;
  }
}

.single-price .top-sec {
  border-bottom: 1px solid #eeeeee;
  padding: 40px 40px 22px 40px;
  text-align: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-price .top-sec br {
  display: none;
}

@media (max-width: 1024px) {
  .single-price .top-sec br {
    display: block;
  }
}

.single-price .end-sec {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 40px;
}

.single-price .end-sec ul li {
  margin-bottom: 20px;
}

.single-price .price-btn {
  background: #f9f9ff;
  color: #222222;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #dddddd;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-price:hover {
  cursor: pointer;
}

.single-price:hover .price-btn {
  color: #fff;
}

@media (max-width: 800px) {
  .single-price {
    margin-bottom: 30px;
  }
}

.whole-wrap {
  background-color: #fff;
}

.generic-banner {
  margin-top: 60px;
  background-color: #46dbf9;
  text-align: center;
}

.generic-banner .height {
  height: 600px;
}

@media (max-width: 767.98px) {
  .generic-banner .height {
    height: 400px;
  }
}

.generic-banner .generic-banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content h2 br {
    display: none;
  }
}

.generic-banner .generic-banner-content p {
  text-align: center;
  font-size: 16px;
}

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content p br {
    display: none;
  }
}

.generic-content h1 {
  font-weight: 600;
}

.about-generic-area {
  background: #fff;
}

.about-generic-area p {
  margin-bottom: 20px;
}

.white-bg {
  background: #fff;
}

.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

.switch-wrap {
  margin-bottom: 10px;
}

.switch-wrap p {
  margin: 0;
}

.link-nav {
  margin-top: 10px;
}

.about-content {
  margin-top: 120px;
  padding: 120px 0px;
  text-align: center;
}

.about-content h1 {
  font-size: 48px;
  font-weight: 600;
}

.about-content a {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}

.about-content .lnr {
  margin: 0px 10px;
  font-weight: 600;
}

.aboutus-about {
  background: #fff !important;
}

.aboutus-cat {
  background: #f9f9ff !important;
}

.aboutus-feedback {
  background: #fff;
}

.contact-page-area .map-wrap {
  margin-bottom: 120px;
}

@media (max-width: 960px) {
  .contact-page-area .map-wrap {
    margin-bottom: 50px;
  }
}

.contact-page-area .contact-btns {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
  color: #fff;
  margin-bottom: 30px;
}

.contact-page-area .contact-btns:hover {
  color: #fff;
}

.contact-page-area .form-area input {
  padding: 15px;
}

.contact-page-area .form-area input,
.contact-page-area .form-area textarea {
  border-radius: 0;
  font-size: 12px;
  line-height: 1.2;
}

.contact-page-area .form-area textarea {
  height: 178px;
  margin-top: 0px;
}

.contact-page-area .form-area .primary-btn {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.contact-page-area .form-area .primary-btn:hover {
  border: 1px solid #46dbf9;
  color: #46dbf9 !important;
}

@media (max-width: 960px) {
  .contact-page-area .address-wrap {
    margin-bottom: 50px;
  }
}

.contact-page-area .single-contact-address {
  margin-bottom: 20px;
}

.contact-page-area .single-contact-address h5 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

.contact-page-area .single-contact-address .lnr {
  font-size: 30px;
  font-weight: 500;
  color: #46dbf9;
  margin-right: 30px;
}

/*
################
               Start Blog Details  Page style
################
*/
.nav-area {
  border-bottom: 1px solid #eee;
}

.nav-area a {
  color: #000;
}

.nav-area .nav-left .thumb {
  padding-right: 20px;
}

.nav-area .nav-right {
  text-align: right;
}

.nav-area .nav-right .thumb {
  padding-left: 20px;
}

@media (max-width: 768px) {
  .nav-area .post-details h4 {
    font-size: 14px;
  }
}

@media (max-width: 466px) {
  .nav-area .nav-right {
    margin-top: 50px;
  }
}

.comment-sec-area {
  border-bottom: 1px solid #eee;
  padding-bottom: 50px;
}

.comment-sec-area a {
  color: #000;
}

.comment-sec-area .comment-list {
  padding-bottom: 30px;
}

.comment-sec-area .comment-list.left-padding {
  padding-left: 25px;
}

.comment-sec-area .thumb {
  margin-right: 20px;
}

.comment-sec-area .date {
  font-size: 13px;
  color: #cccccc;
}

.comment-sec-area .comment {
  color: #777777;
}

.comment-sec-area .btn-reply {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  padding: 8px 30px;
  display: block;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.comment-sec-area .btn-reply:hover {
  background-color: transparent;
  color: #000;
}

.commentform-area {
  padding-bottom: 100px;
}

.commentform-area h5 {
  font-weight: 600 !important;
}

.commentform-area .form-control {
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
}

.commentform-area textarea {
  height: 90%;
}

.commentform-area .form-control {
  border: none;
  background: #f9f9ff;
}

/*
################
               End Blog Details  Page style
################
*/
/* =================================== */
/*  Blog Styles
/* =================================== */
/*============ Start Blog Home Styles  =============*/
.blog_banner {
  min-height: 780px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 0px;
}

.blog_banner .banner_inner {
  background: #04091e;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 780px;
  z-index: 1;
}

.blog_banner .banner_inner .overlay {
  background: url(../img/banner-2.jpg) no-repeat scroll center center;
  opacity: .5;
  height: 125%;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: -1;
}

.blog_banner .banner_inner .blog_b_text {
  max-width: 700px;
  margin: auto;
  color: #fff;
  margin-top: 40px;
}

.blog_banner .banner_inner .blog_b_text h2 {
  font-size: 60px;
  font-weight: 500;
  line-height: 66px;
  margin-bottom: 15px;
}

.blog_banner .banner_inner .blog_b_text p {
  font-size: 16px;
  margin-bottom: 35px;
}

.blog_banner .banner_inner .blog_b_text .white_bg_btn {
  line-height: 42px;
  padding: 0px 45px;
}

.blog-page {
  background: #f9f9ff;
}

.blog-header-content h1 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 414px) {
  .blog-header-content h1 {
    font-size: 30px;
  }
}

.blog-header-content p {
  margin: 20px 0px;
}

.top-category-widget-area {
  background-color: #f9f9ff;
}

.top-category-widget-area .single-cat-widget {
  position: relative;
  text-align: center;
}

.top-category-widget-area .single-cat-widget .overlay-bg {
  background: rgba(0, 0, 0, 0.85);
  margin: 7%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.top-category-widget-area .single-cat-widget img {
  width: 100%;
}

.top-category-widget-area .single-cat-widget .content-details {
  top: 32%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  bottom: 0px;
  position: absolute;
}

.top-category-widget-area .single-cat-widget .content-details h4 {
  color: #fff;
}

.top-category-widget-area .single-cat-widget .content-details span {
  display: inline-block;
  background: #fff;
  width: 60%;
  height: 1%;
}

.top-category-widget-area .single-cat-widget:hover .overlay-bg {
  opacity: .85;
  margin: 7%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media (max-width: 991px) {
  .top-category-widget-area .single-cat-widget {
    margin-bottom: 50px;
  }
}

.post-content-area {
  background-color: #f9f9ff;
}

.post-content-area .single-post {
  margin-bottom: 50px;
}

.post-content-area .single-post .meta-details {
  text-align: right;
  margin-top: 35px;
}

@media (max-width: 767px) {
  .post-content-area .single-post .meta-details {
    text-align: left;
  }
}

.post-content-area .single-post .meta-details .tags {
  margin-bottom: 30px;
}

.post-content-area .single-post .meta-details .tags li {
  display: inline-block;
  font-size: 14px;
}

.post-content-area .single-post .meta-details .tags li a {
  color: #222222;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.post-content-area .single-post .meta-details .tags li a:hover {
  color: #46dbf9;
}

@media (max-width: 1024px) {
  .post-content-area .single-post .meta-details {
    margin-top: 0px;
  }
}

.post-content-area .single-post .user-name a,
.post-content-area .single-post .date a,
.post-content-area .single-post .view a,
.post-content-area .single-post .comments a {
  color: #777777;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.post-content-area .single-post .user-name a:hover,
.post-content-area .single-post .date a:hover,
.post-content-area .single-post .view a:hover,
.post-content-area .single-post .comments a:hover {
  color: #46dbf9;
}

.post-content-area .single-post .user-name .lnr,
.post-content-area .single-post .date .lnr,
.post-content-area .single-post .view .lnr,
.post-content-area .single-post .comments .lnr {
  font-weight: 900;
  color: #222222;
}

.post-content-area .single-post .feature-img img {
  width: 100%;
}

.post-content-area .single-post .posts-title h3 {
  margin: 20px 0px;
}

.post-content-area .single-post .excert {
  margin-bottom: 20px;
}

.post-content-area .single-post .primary-btn {
  background: #f9f9ff;
  color: #222222 !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 0px !important;
  box-shadow: none !important;
  font-weight: 600;
}

.post-content-area .single-post .primary-btn:hover {
  color: #fff !important;
}

.posts-list .posts-title h3 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.posts-list .posts-title h3:hover {
  color: #46dbf9;
}

.blog-pagination {
  padding-top: 60px;
  padding-bottom: 120px;
}

.blog-pagination .page-link {
  border-radius: 0;
}

.blog-pagination .page-item {
  border: none;
}

.page-link {
  background: transparent;
  font-weight: 400;
}

.blog-pagination .page-item.active .page-link {
  border-color: transparent;
  color: #fff;
}

.blog-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #777777;
  border: none;
}

.blog-pagination .page-link .lnr {
  font-weight: 600;
}

.blog-pagination .page-item:last-child .page-link,
.blog-pagination .page-item:first-child .page-link {
  border-radius: 0;
}

.blog-pagination .page-link:hover {
  color: #fff;
  text-decoration: none;
  border-color: #eee;
}

.sidebar-widgets {
  padding-bottom: 120px;
}

.widget-wrap {
  background: #f9f9ff;
  padding: 20px 0px;
  border: 1px solid #eee;
}

@media (max-width: 991px) {
  .widget-wrap {
    margin-top: 50px;
  }
}

.widget-wrap .single-sidebar-widget {
  margin: 30px 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}

.widget-wrap .single-sidebar-widget:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.widget-wrap .search-widget form.search-form input[type=text] {
  color: #222222;
  padding: 10px 22px;
  font-size: 14px;
  border: 1px solid #cccccc;
  float: left;
  width: 80%;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  background: transparent;
}

.widget-wrap .search-widget form.search-form input[type=text]:focus {
  outline: none;
}

.widget-wrap .search-widget ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #222222;
}

.widget-wrap .search-widget ::-moz-placeholder {
  /* Firefox 19+ */
  color: #222222;
}

.widget-wrap .search-widget :-ms-input-placeholder {
  /* IE 10+ */
  color: #222222;
}

.widget-wrap .search-widget :-moz-placeholder {
  /* Firefox 18- */
  color: #222222;
}

.widget-wrap .search-widget form.search-form button {
  float: left;
  width: 20%;
  padding: 10px;
  background: transparent;
  color: #222222;
  font-size: 17px;
  border: 1px solid #cccccc;
  cursor: pointer;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

.widget-wrap .search-widget form.search-form::after {
  content: "";
  clear: both;
  display: table;
}

.widget-wrap .user-info-widget {
  text-align: center;
}

.widget-wrap .user-info-widget a h4 {
  margin-top: 40px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .user-info-widget .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

.widget-wrap .user-info-widget .social-links li a {
  color: #222222;
  padding: 10px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .user-info-widget .social-links li a:hover {
  color: #46dbf9;
}

.widget-wrap .popular-post-widget .popular-title {
  background: #fff;
  text-align: center;
  padding: 12px 0px;
}

.widget-wrap .popular-post-widget .popular-post-list {
  margin-top: 30px;
}

.widget-wrap .popular-post-widget .popular-post-list .thumb img {
  width: 100%;
}

.widget-wrap .popular-post-widget .single-post-list {
  margin-bottom: 20px;
}

.widget-wrap .popular-post-widget .single-post-list .details {
  margin-left: 12px;
}

.widget-wrap .popular-post-widget .single-post-list .details h6 {
  font-weight: 600;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .popular-post-widget .single-post-list .details h6:hover {
  color: #46dbf9;
}

.widget-wrap .popular-post-widget .single-post-list .details p {
  margin-bottom: 0px;
}

.widget-wrap .ads-widget img {
  width: 100%;
}

.widget-wrap .post-category-widget .category-title {
  background: #fff;
  text-align: center;
  text-align: center;
  padding: 12px 0px;
}

.widget-wrap .post-category-widget .cat-list li {
  padding-top: 15px;
  padding-bottom: 8px;
  border-bottom: 2px dotted #eee;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .post-category-widget .cat-list li p {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .post-category-widget .cat-list li a {
  color: #777777;
}

.widget-wrap .post-category-widget .cat-list li:hover {
  border-color: #222222;
}

.widget-wrap .post-category-widget .cat-list li:hover p {
  color: #222222;
  font-weight: 600;
}

.widget-wrap .newsletter-widget .newsletter-title {
  background: #fff;
  text-align: center;
  text-align: center;
  padding: 12px 0px;
}

.widget-wrap .newsletter-widget .col-autos {
  width: 100%;
}

.widget-wrap .newsletter-widget p {
  text-align: center;
  margin: 20px 0px;
}

.widget-wrap .newsletter-widget .bbtns {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 7px 20px;
  border-radius: 0;
}

.widget-wrap .newsletter-widget .input-group-text {
  background: #fff;
  border-radius: 0px;
  vertical-align: top;
}

.widget-wrap .newsletter-widget .input-group-text i {
  color: #ccc;
  margin-top: -1px;
}

.widget-wrap .newsletter-widget .form-control {
  height: 40px;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  font-size: 13px;
  color: #ccc;
  padding: 9px;
  padding-left: 0px;
}

.widget-wrap .newsletter-widget .form-control:focus {
  box-shadow: none;
  border-color: #ccc;
}

.widget-wrap .newsletter-widget ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #ccc;
}

.widget-wrap .newsletter-widget ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #ccc;
}

.widget-wrap .newsletter-widget :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #ccc;
}

.widget-wrap .newsletter-widget :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #ccc;
}

.widget-wrap .newsletter-widget .text-bottom {
  margin-bottom: 0px;
}

.widget-wrap .tag-cloud-widget .tagcloud-title {
  background: #fff;
  text-align: center;
  text-align: center;
  padding: 12px 0px;
  margin-bottom: 30px;
}

.widget-wrap .tag-cloud-widget ul li {
  display: inline-block;
  border: 1px solid #eee;
  background: #fff;
  padding: 4px 14px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-wrap .tag-cloud-widget ul li:hover {
  background: #222222;
}

.widget-wrap .tag-cloud-widget ul li:hover a {
  color: #fff;
}

.widget-wrap .tag-cloud-widget ul li a {
  font-size: 12px;
  color: #222222;
}

/*============ End Blog Home Styles  =============*/
/*============ Start Blog Single Styles  =============*/
.single-post-area {
  padding-top: 80px;
  padding-bottom: 80px;
}

.single-post-area .meta-details {
  margin-top: 20px !important;
}

.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

.single-post-area .social-links li a {
  color: #222222;
  padding: 7px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .social-links li a:hover {
  color: #46dbf9;
}

.single-post-area .quotes {
  margin-top: 20px;
  padding: 30px;
  background-color: white;
  box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
}

.single-post-area .arrow {
  position: absolute;
}

.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

.single-post-area .navigation-area {
  border-top: 1px solid #eee;
  padding-top: 30px;
}

.single-post-area .navigation-area .nav-left {
  text-align: left;
}

.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: .5;
}

@media (max-width: 767px) {
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

.single-post-area .navigation-area .nav-right {
  text-align: right;
}

.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: .5;
}

@media (max-width: 991px) {
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

.comments-area {
  background: #f9f9ff;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-top: 50px;
}

@media (max-width: 414px) {
  .comments-area {
    padding: 50px 8px;
  }
}

.comments-area h4 {
  text-align: center;
  margin-bottom: 50px;
}

.comments-area a {
  color: #222222;
}

.comments-area .comment-list {
  padding-bottom: 30px;
}

.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }

  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }

  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

.comments-area .thumb {
  margin-right: 20px;
}

.comments-area .date {
  font-size: 13px;
  color: #cccccc;
}

.comments-area .comment {
  color: #777777;
}

.comments-area .btn-reply {
  background-color: #fff;
  color: #222222;
  border: 1px solid #eee;
  padding: 8px 30px;
  display: block;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.comments-area .btn-reply:hover {
  background-color: #222222;
  color: #fff;
}

.comment-form {
  background: #f9f9ff;
  text-align: center;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-top: 50px;
}

.comment-form h4 {
  text-align: center;
  margin-bottom: 50px;
}

.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 991px) {
  .comment-form .name {
    padding-right: 0px;
  }
}

.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  .comment-form .email {
    padding-left: 0px;
  }
}

.comment-form .form-control {
  padding: 12px;
  background: #fff;
  border: none;
  border-radius: 0px;
  width: 100%;
  font-size: 13px;
  color: #777777;
}

.comment-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #eee;
}

.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

.comment-form .primary-btn {
  border-radius: 0px !important;
}

/*============ End Blog Single Styles  =============*/
/* =================================== */
/*  Footer Styles
/* =================================== */
.footer-area {
  background: #222222;
}

@media (max-width: 991px) {
  .footer-area .single-footer-widget {
    margin-bottom: 30px;
  }
}

.footer-area .single-footer-widget h4 {
  color: #fff;
  margin-bottom: 35px;
}

@media (max-width: 1024px) {
  .footer-area .single-footer-widget h4 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .footer-area .single-footer-widget h4 {
    margin-bottom: 15px;
  }
}

.footer-area .single-footer-widget ul li {
  margin-bottom: 15px;
}

.footer-area .single-footer-widget ul li a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #777777;
}

.footer-area .single-footer-widget ul li a:hover {
  color: #fff;
}

.footer-area .single-footer-widget .form-wrap {
  margin-top: 25px;
}

.footer-area .single-footer-widget input {
  height: 40px;
  border: none;
  width: 80% !important;
  font-weight: 300;
  background: #f9f9ff;
  color: #222222;
  padding-left: 20px;
  border-radius: 0;
  font-size: 14px;
  padding: 10px;
}

.footer-area .single-footer-widget input:focus {
  outline: none;
}

.footer-area .single-footer-widget .click-btn {
  color: #fff;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 8px 12px;
  border: 0;
}

.footer-area .single-footer-widget .click-btn span {
  font-weight: 500;
}

.footer-area .footer-bottom {
  margin-top: 90px;
}

@media (max-width: 991px) {
  .footer-area .footer-bottom {
    margin-top: 20px;
  }
}

.footer-area .footer-bottom .footer-text {
  color: #fff;
}

.footer-area .footer-bottom .footer-text a,
.footer-area .footer-bottom .footer-text span {
  color: #46dbf9;
}

.footer-area .footer-bottom span {
  font-weight: 700;
}

.footer-area .footer-bottom .footer-social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-area .footer-bottom .footer-social {
    text-align: left;
    margin-top: 30px;
  }
}

.footer-area .footer-bottom .footer-social a {
  background: #3e4876;
  margin-left: 5px;
  width: 40px;
  color: #fff;
  display: inline-grid;
  text-align: center;
  height: 40px;
  align-content: center;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.footer-area .footer-bottom .social-link {
  text-align: right;
}

@media (max-width: 991px) {
  .footer-area .footer-bottom .social-link {
    text-align: left;
    margin-top: 30px;
  }
}

.footer-area .buttons {
  width: auto;
}

.footer-area .footer-bottom .buttons p {
  font-size: 12px;
}

.footer-area .footer-bottom .buttons p span {
  font-size: 18px;
}

/*# sourceMappingURL=main.css.map */