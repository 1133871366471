a:hover,
a:active {
    color: #29c2c1;
    text-decoration: none
}

.bg-theme,
.theme-overlay[data-overlay-dark]:before {
    background-color: #08AEEA;
    background-image: linear-gradient(175deg, #08AEEA 0%, #2AF598 100%);
    

}

.theme-overlay-180[data-overlay-dark]:before {
    background-color: #08AEEA;
    background-image: linear-gradient(175deg, #08AEEA 0%, #2AF598 100%);
    
    
}

.theme-overlay-90[data-overlay-dark]:before {
    background-color: #08AEEA;
background-image: linear-gradient(175deg, #08AEEA 0%, #2AF598 100%);

    
}

.bg-theme-90 {
    background-color: #08AEEA;
background-image: linear-gradient(175deg, #08AEEA 0%, #2AF598 100%);


}

.bg-theme-solid,
.theme-overlay-solid[data-overlay-dark]:before {
    background: #29c2c1
}

.bg-theme-lighter {
    background: rgba(41, 45, 194, 0.15)
}

.bg-theme-light {
    background: #f5fafe
}

.text-yellow {
    color: #ffcc6f
}

.text-green {
    color: #06df9e
}

.text-red {
    color: #d30000
}

.text-orange {
    color: #ff6262
}

.text-blue {
    color: #15b2ec
}

.text-color-md-blue {
    color: #243f67
}

.text-color-light-blue {
    color: #596982
}

.bg-yellow {
    background: rgba(255, 204, 111, 0.2)
}

.bg-green {
    background: rgba(6, 223, 158, 0.1)
}

.bg-orange {
    background: rgba(255, 98, 98, 0.1)
}

.bg-blue {
    background: rgba(21, 178, 236, 0.1)
}

.separator-dashed-line-horrizontal-full {
    border-bottom: 1px dashed #cee1f8 !important
}

.text-theme-color {
    color: #29c2c1
}

.text-content-big p {
    font-weight: 400;
    line-height: 200%;
    margin-bottom: 25px;
    font-size: 15px
}

.service-icon {
    height: 50px;
    width: 50px;
    line-height: 52px;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    margin-right: 15px;
    color: #29c2c1;
    font-size: 22px;
    transition: all 0.3s ease 0s;
    background: rgba(41, 45, 194, 0.15)
}

.hover-icon:hover .service-icon {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    color: #fff;
    transition: all 0.3s ease 0s
}

.img-effect {
    transition-duration: .3s;
    transition-property: transform;
    transition-timing-function: ease-out
}

.img-effect:hover {
    transform: translateY(-8px)
}

.shadow-primary {
    -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2)
}

.line-through {
    text-decoration: line-through
}

.cursor-pointer {
    cursor: pointer
}

.height-100vh {
    height: 100vh
}

.overflow-y-visible {
    overflow-y: visible !important
}

.small-title {
    font-size: 18px;
    line-height: 34px
}

.inner-title {
    font-size: 16px;
    line-height: 34px
}

.story-video {
    height: 100%
}

.min-height-auto {
    min-height: auto
}

input[type=checkbox],
input[type=radio] {
    width: auto;
    margin-right: 10px
}

.form-wrap {
    margin-bottom: 15px
}

input,
textarea,
select,
.form-input {
    min-height: 55px;
    color: #6f6f6f;
    margin-bottom: 0
}

.title-box {
    position: relative;
    padding: 20px 0;
    font-size: 60px;
    line-height: 1;
    width: 100px;
    text-align: right
}

.box-circle {
    left: 0;
    bottom: 0;
    width: 1.37em;
    height: 1.37em;
    position: absolute;
    border-radius: 50%;
    -webkit-transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
    -o-transition: all 0.8s ease-in-out;
    -ms-transition: all 0.8s ease-in-out;
    transition: all 0.8s ease-in-out
}

@media screen and (min-width: 1400px) {
    .container.lg-container {
        max-width: 80%
    }
}

@media screen and (max-width: 991px) {
    .title-box {
        font-size: 54px;
        width: 95px
    }
}

@media screen and (max-width: 767px) {
    .inner-title {
        font-size: 15px;
        line-height: 30px
    }

    .small-title {
        font-size: 16px;
        line-height: 30px
    }
}

@media screen and (max-width: 575px) {
    .title-box {
        font-size: 48px;
        width: 85px
    }

    .mobile-margin-15px-bottom {
        margin-bottom: 15px !important
    }

    .mobile-margin-20px-bottom {
        margin-bottom: 20px !important
    }

    .mobile-margin-25px-bottom {
        margin-bottom: 25px !important
    }

    .mobile-margin-40px-bottom {
        margin-bottom: 40px !important
    }

    .mobile-padding-15px-bottom {
        padding-bottom: 15px !important
    }

    .mobile-padding-20px-bottom {
        padding-bottom: 20px !important
    }

    .mobile-padding-25px-bottom {
        padding-bottom: 25px !important
    }

    .mobile-padding-50px-bottom {
        padding-bottom: 50px !important
    }

    .mobile-display-none {
        display: none
    }

    .mobile-no-margin-right {
        margin-right: 0 !important
    }
}

.scroll-to-top {
    font-size: 20px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    border-radius: 50%;
    background: #000;
    border: 1px solid #2a2a2a;
    width: 35px;
    height: 35px;
    line-height: 30px;
    z-index: 9999;
    outline: 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all 0.3s ease
}

.scroll-to-top i {
    color: #fff
}

.scroll-to-top:hover {
    color: #232323;
    background: #fff
}

.scroll-to-top:hover i {
    color: #232323
}

.scroll-to-top:visited {
    color: #232323;
    text-decoration: none
}

.infinite-floating {
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-name: Floating;
    animation-timing-function: ease-in-out
}

@keyframes Floating {
    0% {
        transform: translate(0px, 0px)
    }

    65% {
        transform: translate(0px, 30px)
    }

    100% {
        transform: translate(0px, 0px)
    }
}

.icon-box {
    position: relative
}

.icon-box .box-circle {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 82px;
    height: 82px;
    background: rgba(78, 74, 200, 0.1);
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.icon-box i {
    font-size: 48px;
    margin-bottom: 20px;
    display: inline-block
}

.icon-box:hover .box-circle {
    transform: scale(1.2)
}

.icon-box .text-yellow {
    color: #ffcc6f
}

.icon-box .text-green {
    color: #06df9e
}

.icon-box .text-orange {
    color: #ff6262
}

.icon-box .text-blue {
    color: #15b2ec
}

.icon-box .bg-yellow {
    background: rgba(255, 204, 111, 0.1)
}

.icon-box .bg-green {
    background: rgba(6, 223, 158, 0.1)
}

.icon-box .bg-orange {
    background: rgba(255, 98, 98, 0.1)
}

.icon-box .bg-blue {
    background: rgba(21, 178, 236, 0.1)
}

.icon-square {
    position: relative;
    text-align: center;
    border: 2px solid transparent;
    vertical-align: top;
    width: 60px;
    height: 60px;
    background: rgba(41, 45, 194, 0.1);
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    line-height: 70px;
    color: #29c2c1
}

.icon-square:after {
    width: 73px;
    height: 73px;
    content: "";
    position: absolute;
    top: -9px;
    left: -9px;
    opacity: 0.8;
    border-radius: 4px;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition: all 0.3s;
    border: 2px solid transparent
}

.icon-square:hover:after {
    border-color: #29c2c1;
    -webkit-transform: scale(1);
    transform: scale(1);
    border: 2px solid
}

.icon-square i {
    font-size: 28px
}

.icon-square.yellow {
    background: rgba(255, 204, 111, 0.1);
    color: #ffcc6f
}

.icon-square.green {
    background: rgba(6, 223, 158, 0.1);
    color: #06df9e
}

.icon-square.orange {
    background: rgba(255, 98, 98, 0.1);
    color: #ff6262
}

.icon-square.blue {
    background: rgba(21, 178, 236, 0.1);
    color: #15b2ec
}

.circle-btn a {
    display: inline-block;
    border: 1px solid #243f67;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    cursor: pointer
}

.circle-btn a:hover {
    color: #fff;
    background: #243f67;
    border-color: #243f67
}

.circle-btn a:hover i {
    color: #fff
}

.circle-btn i {
    color: #243f67
}

.icon-circle {
    vertical-align: top;
    width: 75px;
    height: 75px;
    background: rgba(41, 45, 194, 0.1);
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 85px;
    color: #29c2c1
}

.icon-circle i,
.icon-circle span {
    font-size: 28px
}

.icon-circle.yellow {
    background: rgba(255, 204, 111, 0.1);
    color: #ffcc6f
}

.icon-circle.green {
    background: rgba(6, 223, 158, 0.1);
    color: #06df9e
}

.icon-circle.orange {
    background: rgba(255, 98, 98, 0.1);
    color: #ff6262
}

.icon-circle.blue {
    background: rgba(21, 178, 236, 0.1);
    color: #15b2ec
}

.icon-circle .text {
    font-size: 28px;
    line-height: 75px;
    font-weight: 600
}

@media screen and (max-width: 767px) {
    .icon-circle {
        width: 65px;
        height: 65px;
        line-height: 75px
    }

    .icon-circle .text {
        font-size: 24px;
        line-height: 65px
    }
}

.icon-play {
    width: 55px;
    height: 55px;
    border: solid 1px #fff;
    border-radius: 55px;
    display: inline-block;
    position: relative
}

.icon-play:before {
    content: "";
    width: 10px;
    height: 26px;
    border-left: 2px solid #fff;
    border-radius: 5px;
    position: absolute;
    top: 14px;
    left: 19px
}

.icon-play:after {
    content: "";
    position: absolute;
    top: 16px;
    left: 11px;
    width: 24px;
    height: 21px;
    -webkit-transform: rotate(45deg) skew(-5deg, -5deg);
    transform: rotate(45deg) skew(-5deg, -5deg);
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-radius: 5px
}

.icon-play:hover {
    background-color: rgba(255, 255, 255, 0.1)
}

@media screen and (max-width: 767px) {
    .icon-play {
        width: 50px;
        height: 50px;
        border-radius: 50px
    }

    .icon-play:before {
        width: 10px;
        height: 25px;
        top: 11px;
        left: 17px
    }

    .icon-play:after {
        top: 13px;
        left: 9px;
        width: 22px;
        height: 21px
    }
}

.icon-style {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 20px;
    z-index: 3;
    text-align: center;
    display: inline-block;
    position: relative;
    transition: all 0.4s;
    color: #29c2c1
}

.icon-style:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 15px;
    background: rgba(41, 45, 194, 0.15);
    z-index: -1;
    transform: rotate(45deg);
    transition: all 0.4s
}

.icon-style:hover {
    color: #fff
}

.icon-style:hover:after {
    background: #29c2c1;
    transform: rotate(0deg)
}

@media screen and (max-width: 1199px) {
    .icon-style {
        font-size: 18px;
        width: 50px;
        height: 50px;
        line-height: 50px
    }
}

@media screen and (max-width: 991px) {
    .icon-style {
        font-size: 16px;
        width: 45px;
        height: 45px;
        line-height: 45px;
        margin-bottom: 10px
    }
}

@media screen and (max-width: 767px) {
    .icon-style {
        font-size: 14px;
        width: 40px;
        height: 40px;
        line-height: 40px
    }
}

.list-style li {
    line-height: 24px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.list-style li:last-child {
    margin-bottom: 0
}

.list-style li:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 10px;
    width: 8px;
    height: 8px;
    background: #29c2c1;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    z-index: 2
}

.list-style li:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 6px;
    width: 16px;
    height: 16px;
    background: rgba(41, 194, 186, 0.35);
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    z-index: 1
}

.list-style li:hover {
    padding-left: 35px
}

.list-style2 li {
    line-height: 24px;
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.list-style2 li:last-child {
    margin-bottom: 0
}

.list-style2 li:before {
    content: '';
    position: absolute;
    left: 2px;
    top: 8px;
    width: 8px;
    height: 8px;
    background: #29c2c1;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    z-index: 2;
    transform: rotate(45deg)
}

.list-style2 li:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 6px;
    width: 12px;
    height: 12px;
    background: rgba(41, 194, 186, 0.35);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    z-index: 1;
    transform: rotate(45deg)
}

.list-style2 li:hover {
    padding-left: 35px
}

.list-style3 li {
    line-height: 24px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    position: relative;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.list-style3 li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.list-style3 li:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 10px;
    width: 8px;
    height: 8px;
    background: #29c2c1;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    z-index: 2
}

.list-style3 li:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 6px;
    width: 16px;
    height: 16px;
    background: rgba(41, 194, 186, 0.35);
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    z-index: 1
}

.list-style3 li:hover {
    padding-left: 35px
}

.list-style5 li {
    margin-bottom: 40px
}

.list-style5 li:last-child {
    margin-bottom: 0
}

.list-style5 li h6 {
    margin-bottom: 10px;
    font-weight: 500
}

.list-style5 li .short-desc {
    display: inline-block;
    width: 80%;
    vertical-align: top;
    padding-left: 30px
}

.list-style5 li .short-desc p {
    margin-bottom: 0
}

@media screen and (max-width: 991px) {
    .list-style5 li {
        margin-bottom: 30px
    }
}

@media screen and (max-width: 767px) {
    .list-style5 li .short-desc {
        width: 75%;
        padding-left: 20px
    }
}

.list-style6 li {
    font-size: 16px;
    line-height: 34px;
    padding-left: 30px;
    position: relative
}

.list-style6 li:last-child {
    margin-bottom: 0
}

.list-style6 li:before {
    content: "\e64c";
    font-family: 'themify';
    font-size: 15px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 35px;
    color: #06df9e
}

@media screen and (max-width: 767px) {
    .list-style6 li {
        font-size: 15px;
        line-height: 32px
    }
}

.list-style7 {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-style7 li {
    position: relative;
    padding: 0 0 8px 40px;
    margin: 0 0 8px 0;
    font-size: 16px
}

.list-style7 li span {
    position: absolute;
    top: 3px;
    right: 0
}

.list-style7 li:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0
}

.list-style7 li:before {
    content: ""
}

.list-style7 li i {
    color: #0084e9;
    font-size: 12px;
    background: rgba(0, 132, 233, 0.1);
    margin-right: 12px;
    border-radius: 30px;
    padding: 6px;
    position: absolute;
    left: 0;
    top: 2px;
    line-height: 12px
}

@media screen and (max-width: 767px) {
    .list-style7 li {
        font-size: 15px
    }
}

.list-style8 {
    margin-bottom: 0
}

.list-style8 li {
    font-size: 15px;
    line-height: 30px;
    padding-left: 30px;
    position: relative;
    margin-bottom: 10px
}

.list-style8 li:last-child {
    margin-bottom: 0
}

.list-style8 li:before {
    content: "\e64c";
    font-family: 'themify';
    font-size: 15px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 34px;
    color: #06df9e
}

@media screen and (max-width: 767px) {
    .list-style8 li {
        font-size: 14px;
        line-height: 30px
    }
}

.list-style9 {
    margin-bottom: 0;
    padding-bottom: 0
}

.list-style9 li {
    border-right: 1px solid #d5d5d5;
    padding-right: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    display: inline-block;
    font-size: 14px
}

.list-style9 li:last-child {
    border-right: none;
    padding-right: 0;
    margin-right: 0
}

.butn {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    cursor: pointer;
    font-weight: 600;
    color: #fff;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 28px;
    border-radius: 30px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    -moz-transform: perspective(1px) translateZ(0);
    -o-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition: all linear 400ms;
    -moz-transition: all linear 400ms;
    -o-transition: all linear 400ms;
    transition: all linear 400ms;
    border: none
}

.butn:hover {
    color: #fff
}

a.butn:hover,
a.butn:active {
    color: #fff
}

.butn:before {
    background: #29c2c1;
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: inline-block;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 51%;
    -ms-transform-origin: 51%;
    -o-transform-origin: 51%;
    transform-origin: 51%;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    border-radius: 22px;
    border: none
}

.butn:hover:before,
.butn:focus:before,
.butn:active:before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1)
}

.butn.white {
    color: #29c2c1;
    background: #ffffff;
    background: -webkit-linear-gradient(-45deg, #e0e0e0, #fff);
    background: -moz-linear-gradient(-45deg, #e0e0e0, #fff);
    background: -o-linear-gradient(-45deg, #e0e0e0, #fff);
    background: linear-gradient(-45deg, #e0e0e0, #fff)
}

.butn.white:hover {
    color: #29c2c1
}

a.butn.white:hover,
a.butn.white:active {
    color: #29c2c1
}

.butn.white:before {
    background: #fff
}

.butn.small {
    font-size: 12px;
    line-height: 18px;
    padding: 12px 24px
}

.butn.very-small {
    padding: 8px 20px;
    font-size: 11px
}

.butn:disabled, button[disabled], .butn:disabled:before, button[disabled]:before {
    cursor: inherit;
    opacity: 0.3;
    background: linear-gradient(-45deg, #828383, #96a2aa);
}

.btn.bordered {
    border: 1px solid #fff;
    background: transparent;
    color: #fff
}

@media screen and (max-width: 767px) {
    .butn {
        padding: 12px 24px;
        font-size: 13px
    }
}

.butn.style-one {
    color: #29c2c1;
    background: rgba(41, 45, 194, 0.15);
    display: inline-block;
    padding: 11px 28px;
    border-radius: 4px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit
}

.butn.style-one:before {
    content: none
}

.butn.style-one:hover,
.butn.style-one:active,
.butn.style-one.active {
    background: #29c2c1;
    color: #fff
}

.butn.style-one.fill {
    background: #29c2c1;
    color: #fff;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 30, 0.15)
}

.butn.style-one.fill:hover {
    box-shadow: none
}

.butn.style-one.yellow {
    color: #ffcc6f;
    background: rgba(255, 204, 111, 0.3)
}

.butn.style-one.yellow:hover,
.butn.style-one.yellow:active,
.butn.style-one.yellow.active {
    background: #ffcc6f;
    color: #fff
}

.butn.style-one.green {
    color: #06df9e;
    background: rgba(6, 223, 158, 0.15)
}

.butn.style-one.green:hover,
.butn.style-one.green:active,
.butn.style-one.green.active {
    background: #06df9e;
    color: #fff
}

.butn.style-one.orange {
    color: #ff6262;
    background: rgba(255, 98, 98, 0.15)
}

.butn.style-one.orange:hover,
.butn.style-one.orange:active,
.butn.style-one.orange.active {
    background: #ff6262;
    color: #fff
}

.butn.style-one.blue {
    color: #15b2ec;
    background: rgba(21, 178, 236, 0.15)
}

.butn.style-one.blue:hover,
.butn.style-one.blue:active,
.butn.style-one.blue.active {
    background: #15b2ec;
    color: #fff
}

.butn.style-two {
    color: #fff;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 4px;
    display: inline-block;
    padding: 11px 32px;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit
}

.butn.style-two:before {
    content: none
}

.butn.style-two:hover,
.butn.style-two:active,
.butn.style-two:focus {
    background: #fff;
    border-radius: 4px;
    color: #232323
}

.butn.style-two.reverse {
    background: transparent;
    color: #232323;
    border: 1px solid #6f6f6f;
    border-radius: 4px;
    display: inline-block;
    padding: 11px 32px;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit
}

.butn.style-two:before {
    content: none
}

.butn.style-two.reverse:hover,
.butn.style-two.reverse:focus,
.butn.style-two.reverse:active {
    background: #232323;
    border-radius: 4px;
    color: #fff
}

.butn.style-two.small {
    padding: 6px 20px;
    font-size: 13px;
    line-height: 24px
}

.butn.style-two.medium {
    padding: 8px 22px;
    font-size: 14px;
    line-height: 26px
}

.butn.style-two.md-lg {
    color:#fff;
    padding: 11px 28px;
    font-size: 14px;
    line-height: 26px
}

.butn.style-two.fill {
    background: #29c2c1
}

.butn.style-two.fill:hover,
.butn.style-two.fill:active,
.butn.style-two.fill:focus {
    color: #fff;
    background: #0084e9
}

@media screen and (max-width: 767px) {
    .butn.style-two {
        padding: 8px 24px
    }
}

.butn.style-three {
    color: #fff;
    display: inline-block;
    padding: 11px 28px;
    border-radius: 4px;
    line-height: 28px;
    font-weight: 500;
    text-transform: inherit
}

.butn.style-three:before {
    content: none
}

.butn.style-three:hover,
.butn.style-three:active,
.butn.style-three.active {
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 30, 0.15)
}

.butn.style-three.bordered {
    border: 1px solid #29c2c1;
    color: #29c2c1
}

.butn.style-three.bordered:hover {
    background: #29c2c1;
    border-color: transparent;
    color: #fff
}

.navbar-nav li.current>a,
.navbar-nav>li.active>a {
    color: #29c2c1
}

.navbar>ul>li.current>a:after {
    border-color: transparent #29c2c1 #29c2c1 transparent
}

.navbar ul ul li.active>a {
    color: #29c2c1
}

.white-header-mobile .navbar-nav>li>a {
    font-size: 14px
}

@media screen and (min-width: 992px) {

    .transparent-header .navbar-nav>li.current>a,
    .transparent-header .navbar-nav>li.active>a {
        color: #fff
    }

    .transparent-header.scrollHeader .navbar-nav>li.active>a,
    .transparent-header.scrollHeader .navbar-nav>li.current>a {
        color: #29c2c1
    }

    .transparent-header.scrollHeader .navbar>ul>li.has-sub.current>a:after {
        border-color: transparent #29c2c1 #29c2c1 transparent
    }

    .white-header-mobile .navbar-nav>li>a {
        padding: 21px 0.2rem
    }
}

@media screen and (max-width: 991px) {
    .navbar ul ul li.active>a {
        color: #29c2c1
    }

    .navbar-toggler:after {
        border-top: 2px solid #29c2c1;
        border-bottom: 2px solid #29c2c1
    }

    .navbar-toggler:before {
        background: #29c2c1
    }

    .navbar-toggler.menu-opened:after,
    .navbar-toggler.menu-opened:before {
        background: #29c2c1
    }

    .light-header-mobile {
        background: #edf5ff
    }

    .light-header-mobile .navbar-toggler {
        background: #29c2c1
    }

    .light-header-mobile .navbar-toggler:after {
        border-color: #fff
    }

    .light-header-mobile .navbar-toggler:before {
        background: #fff
    }

    .light-header-mobile .navbar-toggler.menu-opened:after,
    .light-header-mobile .navbar-toggler.menu-opened:before {
        background: #fff
    }

    .white-header-mobile .navbar-toggler {
        top: 14px;
        background: #29c2c1
    }

    .white-header-mobile .navbar-toggler:after {
        border-color: #fff
    }

    .white-header-mobile .navbar-toggler:before,
    .white-header-mobile .navbar-toggler.menu-opened:after {
        background: #fff
    }

    .light-header-mobile .navbar-toggler.menu-opened:before {
        background: #fff
    }

    .white-header-mobile.transparent-header li.search>a {
        color: #232323
    }

    .white-header-mobile .attr-nav ul li.search {
        margin-top: 8px
    }

    .sm-bg-theme {
        background: #29c2c1;
        background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
        background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
        background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
        background: linear-gradient(-45deg, #29c2c1, #0084e9)
    }

    .sm-bg-theme-solid {
        background: #29c2c1
    }

    .sm-bg-theme-90 {
        background: #29c2c1;
        background: -webkit-linear-gradient(90deg, #29c2c1, #0084e9);
        background: -moz-linear-gradient(90deg, #29c2c1, #0084e9);
        background: -o-linear-gradient(90deg, #29c2c1, #0084e9);
        background: linear-gradient(90deg, #29c2c1, #0084e9)
    }

    .sm-bg-theme-180 {
        background: #29c2c1;
        background: -webkit-linear-gradient(180deg, #29c2c1, #0084e9);
        background: -moz-linear-gradient(180deg, #29c2c1, #0084e9);
        background: -o-linear-gradient(180deg, #29c2c1, #0084e9);
        background: linear-gradient(180deg, #29c2c1, #0084e9)
    }
}

.onepage-header.scrollHeader .navbar {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

.onepage-header.dark .navbar .icon-bar {
    color: #232323
}

.onepage-header.dark .navbar-nav .nav-link {
    color: #232323;
    font-size: 15px;
    font-weight: 500;
    text-transform: none
}

.onepage-header.dark .navbar-nav .nav-link:hover,
.onepage-header.dark .navbar-nav .nav-link.active {
    color: #29c2c1;
    opacity: 1
}

.onepage-header.dark.scrollHeader .navbar {
    background: #29c2c1
}

.onepage-header.dark.scrollHeader .navbar-nav .nav-link {
    color: #fff
}

.onepage-header.dark.scrollHeader .navbar-nav .nav-link:hover {
    color: #fff;
    opacity: 0.65
}

.onepage-header.dark.scrollHeader .navbar .navbar-nav .nav-link.active {
    color: #fff;
    opacity: 0.65
}

@media screen and (min-width: 992px) {
    .onepage-header.mobile-app .navbar .nav-item {
        margin-left: 15px
    }

    .onepage-header.mobile-app .navbar .nav-item .nav-link {
        font-size: 14px;
        letter-spacing: 1px
    }
}

@media screen and (max-width: 991px) {
    .onepage-header .navbar-nav .nav-link.active {
        color: #29c2c1
    }

    .onepage-header.scrollHeader .navbar-nav .nav-link:hover,
    .onepage-header.scrollHeader .navbar .navbar-nav .nav-link.active {
        color: #29c2c1
    }

    .onepage-header.dark.scrollHeader .navbar-nav .nav-link:hover,
    .onepage-header.dark.scrollHeader .navbar .navbar-nav .nav-link.active {
        color: #29c2c1;
        opacity: 1
    }

    .onepage-header.dark.scrollHeader .navbar-nav .nav-link {
        color: #232323
    }

    .onepage-header.dark .navbar-toggler {
        background: rgba(41, 45, 194, 0.15)
    }

    .onepage-header.dark .navbar-toggler:after {
        border-color: #29c2c1
    }

    .onepage-header.dark .navbar-toggler:before {
        background: #29c2c1
    }

    .onepage-header.dark .navbar-toggler.menu-opened:after,
    .onepage-header.dark .navbar-toggler.menu-opened:before {
        background: #29c2c1
    }

    .onepage-header.scrollHeader.dark .navbar-toggler {
        background: #f4f4f4
    }

    .onepage-header.scrollHeader.dark .navbar-toggler:after {
        border-color: #29c2c1
    }

    .onepage-header.scrollHeader.dark .navbar-toggler:before {
        background: #29c2c1
    }

    .onepage-header.scrollHeader.dark .navbar-toggler.menu-opened:after,
    .onepage-header.scrollHeader.dark .navbar-toggler.menu-opened:before {
        background: #29c2c1
    }
}

.section-heading {
    margin-bottom: 50px;
    text-align: center
}

.section-heading.half {
    margin-bottom: 30px
}

.section-heading h1,
.section-heading h2,
.section-heading h3,
.section-heading h4,
.section-heading h5,
.section-heading h6 {
    position: relative;
    margin-bottom: 0;
    font-weight: 600;
    position: relative;
    line-height: 140%
}

.section-heading.white h1,
.section-heading.white h2,
.section-heading.white h3,
.section-heading.white h4,
.section-heading.white h5,
.section-heading.white h6 {
    color: #fff
}

.section-heading.grey h1,
.section-heading.grey h2,
.section-heading.grey h3,
.section-heading.grey h4,
.section-heading.grey h5,
.section-heading.grey h6 {
    color: #b7b7b7
}

.section-heading.grey h1:after,
.section-heading.grey h2:after,
.section-heading.grey h3:after,
.section-heading.grey h4:after,
.section-heading.grey h5:after,
.section-heading.grey h6:after {
    background: #b7b7b7
}

.section-heading.left {
    text-align: left
}

.section-heading strong {
    font-weight: 600
}

.section-heading.white p {
    color: #fff
}

.section-heading p {
    font-size: 15px;
    font-weight: 400;
    padding-top: 15px;
    margin: 0 auto
}

.section-heading span {
    position: relative;
    font-size: 15px;
    font-weight: 500;
    color: #29c2c1;
    line-height: 200%;
    margin-bottom: 10px
}

.section-heading.white span {
    color: #fff
}

.section-heading.grey span {
    color: #b7b7b7
}

@media screen and (max-width: 991px) {
    .section-heading {
        margin-bottom: 30px
    }

    .section-heading p {
        padding-top: 10px;
        font-size: 14px
    }
}

.section-heading2 {
    margin-bottom: 50px;
    text-align: center
}

.section-heading2.half {
    margin-bottom: 30px
}

.section-heading2 h1,
.section-heading2 h2,
.section-heading2 h3,
.section-heading2 h4,
.section-heading2 h5,
.section-heading2 h6 {
    position: relative;
    padding-bottom: 15px;
    font-weight: 600;
    margin-bottom: 0;
    position: relative;
    line-height: 140%
}

.section-heading2 h1:after,
.section-heading2 h2:after,
.section-heading2 h3:after,
.section-heading2 h4:after,
.section-heading2 h5:after,
.section-heading2 h6:after {
    content: '';
    display: block;
    width: 100px;
    height: 2px;
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0
}

.section-heading2.white h1,
.section-heading2.white h2,
.section-heading2.white h3,
.section-heading2.white h4,
.section-heading2.white h5,
.section-heading2.white h6 {
    color: #fff
}

.section-heading2.white h1:after,
.section-heading2.white h2:after,
.section-heading2.white h3:after,
.section-heading2.white h4:after,
.section-heading2.white h5:after,
.section-heading2.white h6:after {
    background: #fff
}

.section-heading2.grey h1,
.section-heading2.grey h2,
.section-heading2.grey h3,
.section-heading2.grey h4,
.section-heading2.grey h5,
.section-heading2.grey h6 {
    color: #b7b7b7
}

.section-heading2.grey h1:after,
.section-heading2.grey h2:after,
.section-heading2.grey h3:after,
.section-heading2.grey h4:after,
.section-heading2.grey h5:after,
.section-heading2.grey h6:after {
    background: #b7b7b7
}

.section-heading2.left {
    margin-bottom: 30px;
    text-align: left
}

.section-heading2.left h1,
.section-heading2.left h2,
.section-heading2.left h3,
.section-heading2.left h4,
.section-heading2.left h5,
.section-heading2.left h6 {
    padding-bottom: 15px
}

.section-heading2.left h1:after,
.section-heading2.left h2:after,
.section-heading2.left h3:after,
.section-heading2.left h4:after,
.section-heading2.left h5:after,
.section-heading2.left h6:after {
    margin: 0;
    left: 0
}

.section-heading2 strong {
    font-weight: 600
}

.section-heading2.white p {
    color: #fff
}

.section-heading2 p {
    font-size: 15px;
    font-weight: 400;
    padding-top: 15px;
    margin: 0 auto
}

.section-heading2 span {
    position: relative;
    font-size: 15px;
    font-weight: 600;
    color: #29c2c1;
    line-height: 200%;
    margin-bottom: 5px;
    letter-spacing: 1px;
    display: inline-block
}

.section-heading2.white span {
    color: #fff
}

.section-heading2.grey span {
    color: #b7b7b7
}

@media screen and (max-width: 991px) {
    .section-heading2 {
        margin-bottom: 30px
    }

    .section-heading2 span {
        margin-bottom: 0
    }
}

@media screen and (max-width: 767px) {
    .section-heading2 p {
        font-size: 14px
    }
}

.section-heading3 {
    text-align: center;
    margin-bottom: 50px
}

.section-heading3 h1,
.section-heading3 h2,
.section-heading3 h3,
.section-heading3 h4,
.section-heading3 h5,
.section-heading3 h6 {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px
}

.section-heading3 p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0
}

@media screen and (max-width: 991px) {
    .section-heading3 {
        margin-bottom: 40px
    }

    .section-heading3 h1,
    .section-heading3 h2,
    .section-heading3 h3,
    .section-heading3 h4,
    .section-heading3 h5,
    .section-heading3 h6 {
        font-size: 36px;
        line-height: 48px
    }

    .section-heading3 p {
        font-size: 15px
    }
}

@media screen and (max-width: 767px) {
    .section-heading3 {
        margin-bottom: 35px
    }

    .section-heading3 h1,
    .section-heading3 h2,
    .section-heading3 h3,
    .section-heading3 h4,
    .section-heading3 h5,
    .section-heading3 h6 {
        font-size: 28px;
        line-height: 40px
    }
}

.section-title .title-count {
    position: absolute;
    text-align: center;
    font-weight: bolder;
    top: -18px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    font-size: 160px;
    z-index: 0;
    opacity: 0.1;
    color: #999;
    width: 160px;
    height: 160px;
    line-height: 160px
}

@media screen and (max-width: 991px) {
    .section-title .title-count {
        font-size: 130px;
        line-height: 130px
    }
}

@media screen and (max-width: 767px) {
    .section-title .title-count {
        font-size: 110px;
        line-height: 110px
    }
}

.main-banner-area {
    overflow: hidden;
    position: relative
}

.main-banner-area:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: none;
    z-index: 3
}

.main-banner-area .right-bg {
    float: right;
    width: 55.76%;
    right: 1px;
    overflow: hidden;
    position: relative;
    z-index: 1
}

.main-banner-area .right-bg:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

.main-banner-area .header-shape-bg {
    position: absolute;
    top: 0px;
    min-height: 500px;
    z-index: 2;
    width: 100%
}

.main-banner-area .header-text {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    z-index: 4
}

.main-banner-area .header-text h1 {
    font-weight: 700;
    font-size: 36px;
    color: #29c2c1;
    line-height: 44px;
    letter-spacing: 1.4px;
    margin-bottom: 25px
}

.main-banner-area .header-text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    margin-bottom: 30px
}

.main-banner-area .header-text .email-box {
    width: 85%;
    position: relative;
    background: none;
    overflow: hidden
}

.main-banner-area .header-text .email-box input {
    width: 99%;
    height: 45px;
    min-height: auto;
    margin-bottom: 7px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    border: 1px solid rgba(78, 78, 84, 0.2);
    outline: none;
    padding-left: 20px;
    padding-right: 120px;
    z-index: 1;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.67px
}

.main-banner-area .header-text .email-box .butn {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 2;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    outline: none;
    cursor: pointer;
    padding: 13px 18px;
    border-top-left-radius: 0;
    box-shadow: none;
    border-bottom-left-radius: 0
}

.main-banner-area .header-text .email-box .butn:hover:before,
.main-banner-area .header-text .email-box .butn:focus:before {
    padding: 14px 18px 14px 18px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.main-banner-area .header-text .email-box .input .butn:active:before {
    padding: 14px 18px 14px 18px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.main-banner-area .header-text .email-box span {
    display: block;
    font-size: 13px;
    padding-left: 5px
}

.main-banner-area .play-button-wrapper {
    position: absolute;
    width: 100%;
    top: 45%;
    -webkit-transform: perspective(1px) translateY(-45%);
    -moz-transform: perspective(1px) translateY(-45%);
    -ms-transform: perspective(1px) translateY(-45%);
    -o-transform: perspective(1px) translateY(-45%);
    transform: perspective(1px) translateY(-45%);
    z-index: 4;
    width: 55.5%;
    right: 0px
}

.main-banner-area .play-button-wrapper .btn-play {
    width: 60px;
    height: 60px;
    line-height: 56px;
    text-align: center;
    font-size: 22px;
    color: #fff;
    margin: auto;
    left: 14%;
    right: 0px;
    display: block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
    transform: perspective(1px) translateY(-50%);
    z-index: 3
}

.main-banner-area .play-button-wrapper .btn-play:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #fff;
    opacity: .19;
    z-index: 1
}

.main-banner-area .play-button-wrapper .btn-play i {
    padding-left: 5px;
    position: relative;
    z-index: 2
}

@media screen and (max-width: 1199px) {
    .main-banner-area .right-bg {
        right: 0
    }

    .main-banner-area .header-text {
        top: 70%;
        transform: perspective(1px) translateY(-70%)
    }

    .main-banner-area .header-text h1 {
        font-size: 32px;
        line-height: 42px
    }

    .main-banner-area .header-text .email-box {
        width: 100%
    }
}

@media screen and (max-width: 991px) {
    .main-banner-area .right-bg {
        width: 100%;
        max-height: 550px;
        height: 100%;
        min-height: 410px
    }

    .main-banner-area .right-bg:before {
        opacity: 0.8
    }

    .main-banner-area .header-shape-bg {
        display: none
    }

    .main-banner-area .header-text {
        top: 50%;
        transform: perspective(1px) translateY(-50%)
    }

    .main-banner-area .header-text h1 {
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 10px;
        color: #fff;
        text-align: center
    }

    .main-banner-area .header-text p {
        color: #fff;
        text-align: center;
        margin-bottom: 20px;
        line-height: 24px;
        font-size: 14px
    }

    .main-banner-area .header-text .email-box {
        width: 100%
    }

    .main-banner-area .header-text .email-box span {
        color: #fff;
        text-align: center
    }

    .main-banner-area .play-button-wrapper {
        display: none
    }
}

@media screen and (max-width: 767px) {
    .main-banner-area .right-bg img {
        width: auto;
        max-width: 144%
    }

    .main-banner-area .header-text h1 {
        font-size: 24px;
        line-height: 30px
    }
}

.banner-creative {
    background: #edf5ff;
    padding: 15% 0
}

.banner-creative .banner-img img {
    position: absolute;
    left: -15px;
    top: -38%
}

.banner-creative .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3
}

.banner-creative .btn-play:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #fff;
    opacity: .19;
    z-index: 1
}

.banner-creative .btn-play i {
    padding-left: 5px;
    position: relative;
    z-index: 2
}

.banner-creative .header-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%
}

.banner-creative .header-shape img {
    max-width: none
}

.banner-creative .btn-play-theme {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #29c2c1;
    margin: auto;
    display: inline-block;
    border: 2px solid #29c2c1;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3
}

.banner-creative .btn-play-theme:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #fff;
    opacity: .19;
    z-index: 1
}

.banner-creative .btn-play-theme i {
    padding-left: 5px;
    position: relative;
    z-index: 2
}

@media screen and (max-width: 1399px) {
    .banner-creative {
        padding: 17% 0
    }
}

@media screen and (max-width: 1199px) {
    .banner-creative .banner-img img {
        top: -18%
    }
}

@media screen and (max-width: 991px) {
    .banner-creative {
        padding: 50px 0 50px 0
    }

    .banner-creative .banner-img img {
        position: relative;
        width: auto;
        float: none !important;
        left: 0
    }
}

@media screen and (max-width: 767px) {
    .banner-creative {
        padding: 30px 0
    }

    .banner-creative .btn-play,
    .banner-creative .btn-play-theme {
        width: 40px;
        height: 40px;
        line-height: 36px;
        font-size: 16px
    }
}

.creative-banner {
    padding: 15% 0 17% 0;
    transition-property: padding;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.creative-banner-small {
    padding: 5% 0 7% 0;
}

.creative-banner-small .creative-banner-stuff {
    display: none;
}

.creative-banner .creative-banner-img {
    position: absolute;
    left: -15px;
    top: -50%
}

.creative-banner .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3
}

.creative-banner .btn-play:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #fff;
    opacity: .19;
    z-index: 1
}

.creative-banner .btn-play i {
    padding-left: 5px;
    position: relative;
    z-index: 2
}

.creative-banner .wave-area {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 1
}

.creative-banner .wave {
    /*background: url("../img/wave.svg") repeat-x;*/
    position: absolute;
    top: -100px;
    width: 6400px;
    height: 100px;
    animation: wave 35s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0)
}

.creative-banner .wave:nth-of-type(2) {
    top: -56px;
    animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
    opacity: 1
}

@keyframes wave {
    0% {
        margin-left: 0
    }

    100% {
        margin-left: -1600px
    }
}

@keyframes swell {

    0%,
    100% {
        transform: translate3d(0, -45px, 0)
    }

    50% {
        transform: translate3d(0, 5px, 0)
    }
}

@media screen and (max-width: 1399px) {
    .creative-banner {
        padding: 18% 0 22% 0
    }
}

@media screen and (max-width: 1199px) {
    .creative-banner .creative-banner-img {
        top: -28%
    }
}

@media screen and (max-width: 991px) {
    .creative-banner {
        padding: 200px 0 50px 0
    }

    .creative-banner .creative-banner-img {
        position: relative;
        width: auto;
        float: none !important;
        left: 0;
        top: 0
    }

    .creative-banner-img {
        text-align: center
    }

    .creative-banner-img img {
        float: none !important
    }
}

@media screen and (max-width: 767px) {
    .creative-banner {
        padding: 200px 0 50px 0
    }

    .creative-banner .btn-play {
        width: 40px;
        height: 40px;
        line-height: 36px;
        font-size: 16px
    }
}

.theme-banner {
    position: relative;
    z-index: 1
}

.theme-banner .container {
    padding-top: 12%
}

.theme-banner .banner-wrapper-position {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 50%;
    width: 100%;
    height: 100%
}

.theme-banner .banner-wrapper {
    position: relative;
    z-index: 5
}

.theme-banner .banner-wrapper .slogan {
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-block;
    background: rgba(41, 45, 194, 0.07);
    border: 1px solid transparent;
    line-height: 32px;
    padding: 0 20px;
    font-size: 15px;
    border-radius: 18px
}

.theme-banner .banner-wrapper .slogan span {
    color: #29c2c1
}

.theme-banner .banner-wrapper .main-title {
    font-size: 60px;
    line-height: 80px;
    padding: 25px 0 35px;
    margin-bottom: 0
}

.theme-banner .banner-wrapper .sub-title {
    font-size: 20px;
    line-height: 32px;
    padding-bottom: 28px
}

.theme-banner .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #29c2c1;
    margin: auto;
    display: inline-block;
    border: 2px solid #29c2c1;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3
}

.theme-banner .btn-play:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #fff;
    opacity: .19;
    z-index: 1
}

.theme-banner .btn-play i {
    padding-left: 5px;
    position: relative;
    z-index: 2
}

.theme-banner .btn-play:hover {
    background: rgba(41, 45, 194, 0.15)
}

.theme-banner .screen-one {
    position: absolute;
    right: 8%;
    top: 12%;
    z-index: 1;
    width: 80%
}

.theme-banner .screen-two {
    position: absolute;
    right: 24%;
    top: 27%;
    z-index: 1;
    width: 22%
}

@media screen and (max-width: 1599px) {
    .theme-banner .screen-one {
        top: 17%
    }
}

@media screen and (max-width: 1399px) {
    .theme-banner .banner-wrapper .main-title {
        font-size: 54px;
        line-height: 70px;
        padding: 20px 0 30px
    }
}

@media screen and (max-width: 1199px) {
    .theme-banner .container {
        padding-top: 15%
    }

    .theme-banner .screen-one {
        top: 25%
    }

    .theme-banner .banner-wrapper .main-title {
        font-size: 48px;
        line-height: 65px
    }

    .theme-banner .banner-wrapper .sub-title {
        font-size: 18px;
        padding-bottom: 20px
    }
}

@media screen and (max-width: 991px) {
    .theme-banner {
        min-height: auto !important
    }

    .theme-banner .container {
        padding-top: 0;
        position: relative;
        left: 0;
        top: 0;
        transform: none
    }

    .theme-banner .banner-wrapper-position {
        position: relative;
        margin: 90px auto 70px auto;
        max-width: 75%;
        text-align: center
    }

    .theme-banner .banner-wrapper .main-title {
        font-size: 42px;
        line-height: 58px;
        padding-bottom: 20px
    }

    .theme-banner .banner-wrapper .sub-title {
        padding-bottom: 15px
    }

    .theme-banner .screen-one {
        position: relative;
        top: 0;
        margin: 0 auto;
        left: 0;
        right: 0
    }

    .theme-banner .shape-three {
        left: 0;
        right: inherit
    }
}

@media screen and (max-width: 767px) {
    .theme-banner .banner-wrapper .main-title {
        font-size: 36px;
        line-height: 48px
    }

    .theme-banner .banner-wrapper .sub-title {
        font-size: 16px
    }

    .theme-banner .banner-wrapper .slogan {
        font-size: 14px
    }

    .theme-banner .banner-wrapper-position {
        margin-bottom: 50px;
        max-width: 80%
    }

    .theme-banner .screen-one {
        width: 95%
    }
}

@media screen and (max-width: 575px) {
    .theme-banner .banner-wrapper .main-title {
        font-size: 26px;
        line-height: 36px
    }

    .theme-banner .banner-wrapper .sub-title {
        padding-bottom: 10px
    }
}

.shape-one {
    position: absolute;
    right: 0;
    top: 145px;
    z-index: 0;
    opacity: 0.65
}

.shape-two {
    position: absolute;
    bottom: 12%;
    left: 31%;
    animation: rotated 20s infinite linear
}

.shape-three {
    position: absolute;
    bottom: 35%;
    right: 76%;
    z-index: -1;
    animation: rotated 50s infinite linear;
    width: 14%
}

.shape-four {
    position: absolute;
    top: 17%;
    right: 37%;
    z-index: -1;
    animation: rotatedHalf 30s infinite linear
}

.shape-four:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 95px;
    width: 95px;
    border: 2px solid #0084e9;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite
}

.shape-four:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 80px;
    width: 80px;
    border: 2px solid #0084e9;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite
}

.shape-five {
    position: absolute;
    bottom: 5%;
    left: 58%;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background: #e5a9ff;
    -webkit-animation: rotate3d 10s linear infinite;
    -moz-animation: rotate3d 10s linear infinite;
    -o-animation: rotate3d 10s linear infinite;
    animation: rotate3d 10s linear infinite
}

.triangle-shape {
    top: 15%;
    left: 36%;
    position: absolute;
    animation: animationFramesOne 30s infinite linear;
    opacity: .5;
    position: relative;
    background-color: #0ee8af;
    text-align: left;
    -webkit-transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%
}

.triangle-shape:before {
    -webkit-transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%)
}

.triangle-shape:after,
.triangle-shape:before {
    content: '';
    position: absolute;
    background-color: inherit
}

.triangle-shape:after {
    -webkit-transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%
}

.triangle-shape:before {
    width: 15px;
    height: 15px;
    border-top-right-radius: 30%
}

.triangle-shape:after,
.triangle-shape:before {
    content: '';
    position: absolute;
    background-color: inherit
}

.square-shape {
    opacity: .5;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #007cb7;
    position: absolute;
    top: 23%;
    left: 8%;
    animation: animationFramesTwo 13s infinite linear
}

.square-shape:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: -10px;
    top: 10px;
    border: 10px solid transparent;
    border-top-color: #007cb7
}

.square-shape-two {
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #a3eead;
    bottom: 42%;
    left: 6%;
    animation: animationFramesTwo 25s infinite linear
}

.round-shape-one {
    position: absolute;
    right: 6%;
    top: 10%;
    width: 225px;
    height: 225px;
    border-radius: 50%;
    border: 1px dashed rgba(41, 45, 194, 0.1);
    animation: rotated 30s infinite linear
}

.round-shape-one:before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: -5px;
    background: #ddabf6;
    border-radius: 50%
}

.round-shape-two {
    position: absolute;
    right: -350px;
    top: 15%;
    width: 500px;
    height: 500px;
    border-radius: 100%;
    border: 2px dashed rgba(41, 45, 194, 0.09);
    animation: rotated 50s infinite linear
}

.round-shape-two:before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: -5px;
    background: #ddabf6;
    border-radius: 50%
}

@media screen and (max-width: 1199px) {
    .round-shape-two {
        right: -450px;
        top: 35%
    }
}

@media screen and (max-width: 991px) {
    .round-shape-two {
        right: -400px;
        top: 35%
    }
}

@media screen and (max-width: 767px) {
    .round-shape-two {
        width: 450px;
        height: 450px;
        right: -370px;
        top: 35%
    }
}

@media screen and (max-width: 575px) {
    .round-shape-two {
        width: 300px;
        height: 300px;
        right: -270px;
        top: 40%
    }
}

.shape-six {
    background: rgba(41, 45, 194, 0.2);
    top: 40%;
    right: 85px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: spin1 2s infinite alternate;
    animation: spin1 2s infinite alternate;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%
}

.right-align {
    right: 5%;
    left: inherit
}

.left-align {
    left: 5%;
    right: inherit
}

.bottom-align {
    bottom: 0;
    top: inherit
}

.top-align {
    top: 0;
    bottom: inherit
}

.round-shape-two.left-minus-align {
    left: -15%;
    right: inherit
}

@media screen and (max-width: 1199px) {
    .round-shape-two.left-minus-align {
        left: -450px;
        top: 35%
    }
}

@media screen and (max-width: 991px) {
    .round-shape-two.left-minus-align {
        left: -400px;
        top: 35%
    }
}

@media screen and (max-width: 767px) {
    .round-shape-two.left-minus-align {
        width: 450px;
        height: 450px;
        left: -370px;
        top: 35%
    }
}

@media screen and (max-width: 575px) {
    .round-shape-two.left-minus-align {
        width: 300px;
        height: 300px;
        left: -270px;
        top: 40%
    }
}

@keyframes spin1 {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9)
    }

    100% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
}

@keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg)
    }

    100% {
        -webkit-transform: rotateY(360deg);
        -moz-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg)
    }
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1)
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2)
    }
}

@-webkit-keyframes rotated {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotated {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes rotatedHalf {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes rotatedHalf {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg)
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg)
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg)
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg)
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg)
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg)
    }
}

@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg)
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg)
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg)
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg)
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg)
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg)
    }
}

@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1)
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9)
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1)
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2)
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1)
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1)
    }
}

@-webkit-keyframes animationFramesTwo {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1)
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg) scale(0.9)
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg) scale(1)
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg) scale(1.2)
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg) scale(1.1)
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg) scale(1)
    }
}

@media screen and (max-width: 1199px) {
    .round-shape-one {
        top: 20%;
        width: 150px;
        height: 150px;
        right: 8%
    }
}

@media screen and (max-width: 575px) {
    .round-shape-one {
        right: 12%
    }
}

.creative-banner-area {
    width: 100%;
    position: relative;
    background: #29c2c1;
    background: -webkit-linear-gradient(180deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(180deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(180deg, #29c2c1, #0084e9);
    background: linear-gradient(180deg, #29c2c1, #0084e9)
}

.creative-banner-area .container {
    position: absolute;
    left: 50%;
    top: 40%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9
}

.creative-banner-area .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3
}

.creative-banner-area .btn-play:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #fff;
    opacity: .19;
    z-index: 1
}

.creative-banner-area .btn-play i {
    padding-left: 5px;
    position: relative;
    z-index: 2
}

.creative-banner-area .banner-content-img {
    width: 55%;
    height: auto;
    text-align: center;
    position: absolute;
    bottom: -150px;
    right: 0;
    z-index: 9;
    left: 0;
    margin: 0 auto
}

.creative-banner-area .header-shape {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%
}

.creative-banner-area .header-shape img {
    max-width: none
}

@media screen and (max-width: 1599px) {
    .creative-banner-area .banner-content-img {
        bottom: -125px
    }
}

@media screen and (max-width: 1399px) {
    .creative-banner-area .container {
        top: 45%
    }

    .creative-banner-area .banner-content-img {
        bottom: -150px
    }
}

@media screen and (max-width: 1199px) {
    .creative-banner-area .banner-content-img {
        bottom: -120px
    }
}

@media screen and (max-width: 767px) {
    .creative-banner-area .container {
        top: 45%
    }

    .creative-banner-area .banner-content-img {
        bottom: -40px;
        width: 75%
    }
}

.main-banner {
    position: relative
}

.main-banner .header-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: 0;
    width: 100%
}

.main-banner .header-shape img {
    max-width: none
}

.main-banner>.container {
    padding-top: 15%;
    position: relative;
    z-index: 9
}

.main-banner .banner-wrapper-position {
    position: absolute;
    right: 50px;
    top: 130px;
    max-width: 40%;
    width: 100%;
    height: auto;
    z-index: 9
}

.main-banner .btn-play {
    width: 50px;
    height: 50px;
    line-height: 46px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3
}

.main-banner .btn-play:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #fff;
    opacity: .19;
    z-index: 1
}

.main-banner .btn-play i {
    padding-left: 5px;
    position: relative;
    z-index: 2
}

.main-banner .header-text h1 {
    position: relative;
    font-size: 65px;
    line-height: 85px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 15px;
    letter-spacing: 1px
}

.main-banner .banner-content-img {
    width: 55%;
    height: 66%;
    right: 30px;
    text-align: right;
    position: absolute;
    bottom: 100px;
    z-index: 9
}

.main-banner .header-text p {
    line-height: 30px
}

@media screen and (max-width: 1399px) {
    .main-banner .banner-wrapper-position {
        max-width: 45%
    }

    .main-banner .header-text h1 {
        font-size: 58px;
        line-height: 75px
    }
}

@media screen and (max-width: 1199px) {
    .main-banner>.container {
        padding-top: 18%
    }
}

@media screen and (max-width: 991px) {
    .main-banner {
        padding: 90px 0 120px 0
    }

    .main-banner>.container {
        padding-top: 0;
        position: relative;
        top: 0;
        transform: none;
        left: 0
    }

    .main-banner .banner-wrapper-position {
        position: relative;
        right: 0;
        top: 0;
        text-align: center;
        margin: 0 auto 50px auto;
        max-width: 60%
    }

    .main-banner .header-text {
        text-align: center;
        padding: 0 7%
    }

    .main-banner .header-text h1 {
        font-size: 52px;
        line-height: 65px
    }
}

@media screen and (max-width: 767px) {
    .main-banner {
        padding: 90px 0
    }

    .main-banner .banner-wrapper-position {
        max-width: 65%
    }

    .main-banner .header-text {
        padding: 0 15px
    }

    .main-banner .header-text h1 {
        font-size: 42px;
        line-height: 48px
    }
}

@media screen and (max-width: 575px) {
    .main-banner .header-text h1 {
        font-size: 34px;
        line-height: 42px
    }

    .main-banner>.container {
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%)
    }
}

.animated-banner-area {
    position: relative;
    overflow: hidden
}

.animated-banner-area>.container {
    position: absolute;
    left: 50%;
    top: 45%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9
}

.animated-banner-area h1 {
    padding: 0 0 15px 0;
    color: #fff;
    font-size: 58px;
    line-height: 70px;
    font-weight: 300
}

.animated-banner-area p {
    padding: 0 0 30px 0;
    font-size: 22px;
    line-height: 36px;
    color: #fff;
    letter-spacing: 0.5px;
    font-weight: 300
}

.animated-banner-area .btn-play {
    width: 60px;
    height: 60px;
    line-height: 65px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    margin: auto;
    display: inline-block;
    border: 2px solid #fff;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    z-index: 3
}

.animated-banner-area .btn-play i {
    position: relative;
    z-index: 2;
    font-size: 28px
}

.animated-banner-area .banner-content-img {
    position: absolute;
    right: 20px;
    top: 120px;
    max-width: 45%;
    width: 100%;
    z-index: 9
}

.animated-banner-area .header-shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.animated-banner-area .header-shape img {
    max-width: none;
    height: 100%
}

@media screen and (max-width: 1399px) {
    .animated-banner-area>.container {
        left: 52%
    }

    .animated-banner-area h1 {
        font-size: 52px;
        margin-bottom: 10px
    }

    .animated-banner-area p {
        padding-bottom: 10px;
        font-size: 18px;
        line-height: 36px
    }
}

@media screen and (max-width: 1199px) {
    .animated-banner-area>.container {
        left: 54%
    }

    .animated-banner-area h1 {
        font-size: 44px
    }

    .animated-banner-area p {
        font-size: 16px;
        line-height: 34px
    }
}

@media screen and (max-width: 1024px) {
    .animated-banner-area {
        min-height: 750px !important
    }
}

@media screen and (max-width: 991px) {
    .animated-banner-area {
        padding: 70px 0 90px 0;
        min-height: inherit !important
    }

    .animated-banner-area>.container {
        left: 50%
    }

    .animated-banner-area h1 {
        font-size: 42px;
        line-height: 58px
    }

    .animated-banner-area .banner-content-img {
        position: relative;
        left: 0;
        right: 0;
        width: 100%;
        top: 0;
        text-align: center;
        height: auto;
        max-width: 80%;
        margin: 0 auto 50px auto
    }

    .animated-banner-area>.container {
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 100%;
        top: 0;
        transform: none
    }

    .animated-banner-area p {
        margin-bottom: 20px
    }
}

@media screen and (max-width: 575px) {
    .animated-banner-area {
        padding: 30px 0 50px 0
    }

    .animated-banner-area h1 {
        font-size: 28px;
        line-height: 48px;
        margin-bottom: 0
    }

    .animated-banner-area .banner-content-img {
        max-width: 90%;
        margin-bottom: 30px
    }

    .animated-banner-area p {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 20px
    }
}

.page-title-section {
    padding: 200px 0 125px;
    text-align: center
}

.page-title-section h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    color: #fff;
    margin-bottom: 0
}

.page-title-section ul {
    margin-bottom: 0;
    margin-top: 15px;
    text-align: center
}

.page-title-section ul li {
    display: inline-block
}

.page-title-section ul li:last-child a {
    color: #fff;
    font-weight: 600;
    opacity: 0.65
}

.page-title-section ul li:after {
    content: '\f105';
    font-weight: 700;
    vertical-align: middle;
    color: #fff;
    font-family: Font Awesome\ 5 Free;
    padding: 0 5px 0 10px
}

.page-title-section ul li:last-child:after {
    content: none
}

.page-title-section ul li a {
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

.page-title-section .active a,
.page-title-section li.active:last-child a {
    color: #fff
}

@media screen and (max-width: 1199px) {
    .page-title-section {
        padding: 165px 0 100px 0
    }

    .page-title-section h1 {
        font-size: 36px;
        line-height: 42px
    }
}

@media screen and (max-width: 991px) {
    .page-title-section {
        padding: 140px 0 90px 0
    }

    .page-title-section h1 {
        font-size: 32px;
        line-height: 40px
    }

    .page-title-section ul {
        margin-top: 5px
    }

    .page-title-section ul li a {
        font-size: 13px
    }
}

@media screen and (max-width: 767px) {
    .page-title-section {
        padding: 140px 0 75px 0
    }
}

.feature-block1 {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: rgba(255, 255, 255, 0.85)
}

.feature-block1.line {
    position: relative
}

.feature-block1.line:after {
    content: '';
    position: absolute;
    width: 69%;
    height: 2px;
    top: 50px;
    left: 70%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border: 1px dashed #fff
}

.feature-block1 .icon {
    width: 100px;
    height: 100px;
    margin: auto;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    margin-bottom: 35px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
}

.feature-block1 .icon i {
    display: block;
    width: 100px;
    height: 100px;
    line-height: 95px;
    color: #29c2c1;
    font-size: 40px;
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    transform: rotate(315deg)
}

.feature-block1 .title {
    font-size: 18px;
    letter-spacing: 1.12px;
    margin-bottom: 15px;
    color: #fff
}

.feature-block1:hover .icon {
    background: #29c2c1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg)
}

.feature-block1:hover .icon i {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    color: #fff
}

@media screen and (max-width: 1199px) {
    .feature-block1 .icon i {
        width: 90px;
        height: 90px;
        line-height: 90px;
        font-size: 36px
    }

    .feature-block1 .title {
        margin-bottom: 10px
    }

    .feature-block1 .icon {
        width: 90px;
        height: 90px;
        margin-bottom: 25px
    }

    .feature-block1.line:after {
        width: 65%;
        left: 73%
    }
}

@media screen and (max-width: 991px) {
    .feature-block1 .title {
        font-size: 17px
    }

    .feature-block1 .icon {
        width: 80px;
        height: 80px;
        margin-bottom: 20px
    }

    .feature-block1 .icon i {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 32px
    }

    .feature-block1.line:after {
        width: 65%;
        left: 75%;
        top: 40px
    }
}

@media screen and (max-width: 767px) {
    .feature-block1 {
        margin-bottom: 90px
    }

    .feature-block1 .title {
        font-size: 16px;
        margin-bottom: 5px
    }

    .feature-block1 .icon {
        width: 70px;
        height: 70px;
        margin-bottom: 15px
    }

    .feature-block1 .icon i {
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 28px
    }

    .feature-block1.line {
        top: 0
    }

    .feature-block1.line:after {
        width: 1px;
        left: 0;
        top: auto;
        bottom: -42%;
        right: 0;
        margin: 0 auto;
        height: 35%
    }
}

@media screen and (max-width: 575px) {
    .feature-block1.line:after {
        bottom: -38%
    }
}

.feature-block2 {
    box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
    margin: 30px;
    padding: 60px 40px 60px 40px
}

.feature-block2 h4 {
    margin-bottom: 20px
}

.feature-block2 h4 a {
    color: #232323;
    font-size: 18px
}

.feature-block2 h4 a:hover {
    color: #29c2c1
}

.feature-block2 p {
    line-height: 30px
}

.feature-block2 .feature-box-img4 {
    height: 120px;
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    background-color: #e6ecff;
    padding: 0 15px;
    margin: 0 auto 30px auto
}

.feature-block2 .feature-box-img4 img {
    border-radius: 50%
}

.feature-block2 a span {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px
}

@media screen and (max-width: 1199px) {
    .feature-block2 {
        padding: 50px 30px 50px 30px
    }
}

@media screen and (max-width: 767px) {
    .feature-block2 {
        padding: 35px 25px 35px 25px
    }
}

.feature-block3:nth-child(1) {
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec
}

.feature-block3:nth-child(2) {
    border-bottom: 1px solid #ececec
}

.feature-block3:nth-child(3) {
    border-right: 1px solid #ececec
}

.feature-block3 .inner-box {
    position: relative;
    text-align: center;
    padding: 100px 15px 25px 15px;
    display: inline-block;
    width: 100%
}

.feature-block3 .icon-box .feature-icon {
    position: relative
}

@media screen and (max-width: 575px) {
    .feature-block3 .inner-box {
        padding: 80px 0 15px 0
    }
}

.feature-img {
    text-align: center;
    position: relative;
    z-index: 1
}

.feature-img .circle {
    bottom: 0;
    left: -86px;
    right: auto;
    width: 500px;
    height: 500px;
    background: #d8a608;
    position: absolute;
    border-radius: 100%;
    z-index: -1;
    opacity: 0.06
}

.feature-img .circle.two {
    right: 60px;
    width: 370px;
    height: 370px;
    left: 120px;
    bottom: inherit;
    background: #d8a608;
    top: -40px;
    z-index: -2;
    opacity: 0.06;
    position: absolute;
    border-radius: 100%
}

.feature-img.right-align .circle {
    background: #d8a608;
    right: -126px;
    opacity: .06;
    left: auto
}

.feature-img.right-align .circle.two {
    left: 25px;
    right: auto;
    top: 94px;
    z-index: -2
}

@media screen and (max-width: 1199px) {
    .feature-img .circle {
        width: 450px;
        height: 450px
    }

    .feature-img .circle.two {
        width: 300px;
        height: 300px
    }
}

@media screen and (max-width: 991px) {
    .feature-img {
        left: 0;
        right: 0;
        margin: 0 auto
    }

    .feature-img.right-align .circle.two {
        left: auto;
        right: 25px;
        top: -50px
    }
}

@media screen and (max-width: 575px) {
    .feature-img .circle {
        width: 80%;
        height: 80%
    }

    .feature-img .circle.two {
        width: 150px;
        height: 150px
    }
}

.features-block4 {
    background: #fff;
    box-shadow: 0px 3px 10px 0px rgba(41, 45, 194, 0.08);
    border-radius: 4px;
    margin: 0px 10px 0px 0px;
    position: relative;
    z-index: 5;
    padding: 55px 35px 45px 35px;
    transition: all 0.6s linear;
    height: 100%
}

.features-block4:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    z-index: -3;
    opacity: 0;
    transition: all 0.6s linear;
    border-radius: 4px;
    width: 100%;
    height: 100%
}

.features-block4:hover:after {
    opacity: 1
}

.features-block4 .icon {
    font-size: 40px
}

.features-block4 h5 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px
}

.features-block4 p {
    font-size: 16px;
    line-height: 28px
}

.features-block4 .title-box {
    margin: 0 0 50px 0
}

.features-block4 i {
    transition: all 0.3s linear
}

.features-block4:hover {
    opacity: 1;
    transform: translate(0%, 0%);
    transition: all 1.3s linear
}

.features-block4:hover .box-circle {
    transform: scale(1.2)
}

.features-block4:hover h5,
.features-block4:hover p,
.features-block4:hover .icon {
    color: #fff
}

.features-block4:hover .box-circle {
    background: rgba(255, 255, 255, 0.1)
}

.features-block4:hover i {
    color: #fff
}

@media screen and (max-width: 1199px) {
    .features-block4 {
        padding: 35px 25px 25px 25px
    }

    .features-block4 .title-box {
        margin: 0 0 40px 0
    }

    .features-block4 h5 {
        font-size: 20px;
        margin-bottom: 25px
    }

    .features-block4 p {
        font-size: 15px
    }

    .features-block4 .icon {
        font-size: 36px
    }
}

@media screen and (max-width: 991px) {
    .features-block4 {
        margin-right: 0
    }

    .features-block4 .title-box {
        margin-bottom: 30px
    }

    .features-block4 h5 {
        margin-bottom: 20px
    }

    .features-block4 .icon {
        font-size: 32px
    }
}

@media screen and (max-width: 575px) {
    .features-block4 {
        padding: 25px
    }

    .features-block4 h5 {
        font-size: 18px
    }

    .features-block4 .title-box {
        margin-bottom: 25px
    }

    .features-block4 h5 {
        margin-bottom: 15px
    }
}

.service-section {
    margin-top: -3.2%
}

@media screen and (max-width: 991px) {
    .service-section {
        margin-top: 0
    }
}

.services-block {
    background: #fff;
    border-radius: 7px;
    text-align: center;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out
}

.services-block:hover {
    box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
    background: #ffffff
}

.services-block:hover i {
    animation-name: elementor-animation-wobble-to-top-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1
}

.services-block:active,
.services-block:focus,
.services-block:hover {
    animation-name: elementor-animation-wobble-to-bottom-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1
}

.services-block.bg-light-gray {
    background: #f7f7f7
}

.services-block .title-box {
    position: relative;
    padding: 20px 0;
    font-size: 60px;
    line-height: 1;
    width: 2em;
    margin-left: auto;
    margin-right: auto
}

.services-block .box-circle-large,
.services-block .box-circle-small {
    position: absolute;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.services-block .box-circle-large {
    left: 0;
    bottom: 0;
    width: 1.37em;
    height: 1.37em;
    background: rgba(74, 178, 200, 0.1);
}

.services-block .box-circle-small {
    top: 0;
    right: 0;
    width: 0.59em;
    height: 0.59em;
    background: rgba(74, 178, 200, 0.1);
}

.services-block:hover {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

.services-block:hover .box-circle-large {
    transform: scale(1.2)
}

.services-block:hover .box-circle-small {
    transform: translate3d(0, -35%, 0) rotate(0deg)
}

.services-block:hover h3,
.services-block:hover p,
.services-block:hover i,
.services-block:hover a,
.services-block:hover .text-extra-dark-gray {
    color: #fff
}

.services-block:hover .box-circle-large {
    background: rgba(255, 255, 255, 0.1)
}

.services-block:hover .box-circle-small {
    background: rgba(255, 255, 255, 0.2)
}

.services-block.active {
    background: #29c2c1
}

.services-block.active h3,
.services-block.active p,
.services-block.active i {
    color: #fff
}

.services-block.active .box-circle-large {
    background: rgba(255, 255, 255, 0.1)
}

.services-block.active .box-circle-small {
    background: rgba(255, 255, 255, 0.2)
}

@keyframes elementor-animation-wobble-to-top-right {
    16.65% {
        transform: translate(8px, -8px)
    }

    33.3% {
        transform: translate(-6px, 6px)
    }

    49.95% {
        transform: translate(4px, -4px)
    }

    66.6% {
        transform: translate(-2px, 2px)
    }

    83.25% {
        transform: translate(1px, -1px)
    }

    100% {
        transform: translate(0, 0)
    }
}

@media screen and (max-width: 991px) {
    .services-block .title-box {
        font-size: 50px
    }
}

@media screen and (max-width: 767px) {
    .services-block .title-box {
        font-size: 42px
    }
}

.services-block2 {
    text-align: center;
    background: #fff
}

.services-block2:hover i {
    color: #fff
}

.services-block2:hover i:after {
    background: #29c2c1;
    transform: rotate(0deg)
}

.services-block2 i {
    display: inline-block;
    color: #29c2c1;
    font-size: 45px;
    width: 85px;
    height: 85px;
    line-height: 85px;
    margin-bottom: 30px;
    position: relative;
    transition: all 0.4s;
    z-index: 3
}

.services-block2 i:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 20px;
    background: rgba(41, 45, 194, 0.15);
    z-index: -1;
    transform: rotate(45deg);
    transition: all 0.4s
}

@media screen and (max-width: 1199px) {
    .services-block2 i {
        font-size: 34px;
        width: 75px;
        height: 75px;
        line-height: 75px;
        margin-bottom: 25px
    }
}

@media screen and (max-width: 991px) {
    .services-block2 i {
        margin-bottom: 20px
    }
}

@media screen and (max-width: 767px) {
    .services-block2 i {
        font-size: 30px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        margin-bottom: 15px
    }
}

.service-block3 {
    text-align: center
}

.service-block3 p {
    margin-bottom: 0
}

.service-block3 .icon-box4 {
    height: 60px;
    width: 60px;
    font-size: 26px;
    box-shadow: 0 14px 26px -12px rgba(103, 58, 183, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(103, 58, 183, 0.2) !important;
    border-radius: 4px;
    position: relative;
    text-align: center;
    display: inline-block;
    line-height: 63px
}

.service-block3 .icon-box4:after {
    width: 76px;
    height: 76px;
    content: "";
    position: absolute;
    top: -8px;
    left: -8px;
    opacity: 0.8;
    border-radius: 5px;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition: all 0.3s;
    border: 2px solid transparent
}

.service-block3:hover .icon-box4:after {
    -webkit-transform: scale(1);
    transform: scale(1);
    border: 2px solid
}

.service-block3 p {
    line-height: 30px;
    margin: 0 auto
}

.service-block3 h5 {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 15px
}

.service-block3 .bg1 {
    background: #29c2c1
}

.service-block3 .bg2 {
    background: #f7864e
}

.service-block3 .bg3 {
    background: #35c3ec
}

.service-block3 .bg4 {
    background: #e8bb20
}

.service-block3 .bg5 {
    background: #f94897
}

.service-block3 .bg6 {
    background: #5abb29
}

.service-block3:hover .icon-box4.bg1:after {
    border-color: #29c2c1
}

.service-block3:hover .icon-box4.bg2:after {
    border-color: #f7864e
}

.service-block3:hover .icon-box4.bg3:after {
    border-color: #35c3ec
}

.service-block3:hover .icon-box4.bg4:after {
    border-color: #e8bb20
}

.service-block3:hover .icon-box4.bg5:after {
    border-color: #f94897
}

.service-block3:hover .icon-box4.bg6:after {
    border-color: #5abb29
}

.service-block5 {
    text-align: center;
    padding: 40px 20px;
    transition: all .3s ease-out
}

.service-block5:hover {
    background-color: #fff;
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.18);
    transform: translate(0, -10px);
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px)
}

.service-block5 p {
    font-size: 16px;
    line-height: 30px
}

.service-block5 .icon-box5 {
    font-size: 26px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 80px;
    margin: auto;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    animation: one-animated 8s infinite;
    margin-bottom: 40px
}

.service-block5 .service-icon1 {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 60px;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    animation: one-animated 8s infinite
}

@keyframes one-animated {
    0% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2)
    }

    25% {
        border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%
    }

    50% {
        border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
        box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2)
    }

    75% {
        border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%
    }
}

@media screen and (max-width: 991px) {
    .service-block5 {
        padding: 30px 10px
    }

    .service-block5 .icon-box5 {
        height: 70px;
        margin-bottom: 25px
    }
}

@media screen and (max-width: 767px) {
    .service-block5 p {
        font-size: 15px;
        line-height: 28px
    }
}

.service-block6 {
    border: 1px solid #f4f4f9;
    background-color: white;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
    box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.04);
    padding: 50px;
    webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear
}

.service-block6:hover {
    -webkit-box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08);
    box-shadow: 0px 20px 40px 0px rgba(12, 0, 46, 0.08)
}

.service-block6 h5 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #243f67
}

.service-block6 p {
    margin-bottom: 0;
    line-height: 28px
}

.service-block6:hover .service-img {
    -webkit-animation-name: hvr-buzz;
    animation-name: hvr-buzz;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

@-webkit-keyframes hvr-buzz {
    50% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
        -moz-transform: translateX(3px) rotate(2deg);
        -ms-transform: translateX(3px) rotate(2deg);
        -o-transform: translateX(3px) rotate(2deg)
    }

    100% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
        -moz-transform: translateX(-3px) rotate(-2deg);
        -ms-transform: translateX(-3px) rotate(-2deg);
        -o-transform: translateX(-3px) rotate(-2deg)
    }
}

@media screen and (max-width: 1199px) {
    .service-block6 {
        padding: 40px
    }
}

@media screen and (max-width: 991px) {
    .service-block6 {
        padding: 35px
    }

    .service-block6 h5 {
        font-size: 18px
    }
}

@media screen and (max-width: 575px) {
    .service-block6 {
        padding: 30px 25px
    }
}

.services-block7 {
    background: #fff;
    box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
    padding: 40px 15px 65px 15px;
    border-radius: 7px;
    text-align: center;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out
}

.services-block7:hover {
    box-shadow: -1px 9px 18px 0px rgba(75, 81, 91, 0.1);
    background: #ffffff
}

.services-block7:active {
    animation-name: elementor-animation-wobble-to-bottom-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1
}

.services-block:focus,
.services-block:hover {
    animation-name: elementor-animation-wobble-to-bottom-right;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1
}

.services-block7.bg-light-gray {
    background: #f7f7f7
}

.services-block7 p {
    margin-bottom: 0;
    line-height: 30px
}

.services-block7 .title-box {
    margin-left: auto;
    margin-right: auto
}

.services-block7 .box-circle-large {
    position: absolute;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    left: 0;
    bottom: 0;
    width: 1.37em;
    height: 1.37em
}

.services-block7:hover .box-circle-large {
    transform: scale(1.2)
}

@media screen and (max-width: 1199px) {
    .services-block7 {
        padding: 50px 25px 65px 25px
    }
}

@media screen and (max-width: 991px) {
    .services-block7 {
        padding: 45px 25px 60px 25px
    }
}

@media screen and (max-width: 767px) {
    .services-block7 {
        padding: 35px 20px 60px 20px
    }
}

.image-box {
    position: relative;
    display: block
}

.image-box figure {
    margin-bottom: 0
}

.image-box:before {
    position: absolute;
    content: '';
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    width: 310px;
    height: 310px;
    border-radius: 50%;
    left: -130px;
    top: 25%
}

.image-box img {
    width: 100%
}

.image-box .image-1 {
    position: relative;
    float: left;
    margin-left: -130px
}

.image-box .image-2 {
    position: absolute;
    left: -55px;
    bottom: 310px;
    box-shadow: 0 23px 50px rgba(176, 176, 176, 0.35)
}

.image-box .image-3 {
    position: absolute;
    top: 105px;
    right: 40px
}

.about-block7 .about-text:before {
    top: -10px;
    left: 0;
    position: absolute;
    content: "\201E";
    margin-top: -65px;
    font-family: Arial, sans-serif;
    font-size: 110px;
    line-height: 1;
    color: #ddd
}

.about-block7 .about-img img {
    width: 60px;
    border-radius: 50px
}

@media screen and (max-width: 1399px) {
    .image-box:before {
        width: 275px;
        height: 275px;
        left: -100px
    }

    .image-box .image-2 {
        left: -15px
    }
}

@media screen and (max-width: 1199px) {
    .image-box .image-1 {
        margin-left: -180px;
        left: 90px
    }

    .image-box .image-3 {
        right: 0
    }
}

@media screen and (max-width: 991px) {
    .image-box:before {
        left: 0
    }

    .image-box .image-1 {
        margin-left: 0;
        left: 0
    }

    .image-box .image-2 {
        left: 50px
    }

    .about-block7 {
        padding-right: 50px;
        margin-top: -130px;
        padding-bottom: 50px
    }

    .about-block7 .about-text:before {
        font-size: 98px
    }
}

@media screen and (max-width: 575px) {
    .image-box:before {
        content: none
    }

    .image-box .image-3 {
        right: 0;
        width: 30%;
        top: 50px
    }

    .image-box .image-2 {
        left: 0;
        width: 35%;
        bottom: 210px
    }

    .about-block7 {
        margin-top: -50px;
        padding-right: 20px;
        padding-bottom: 20px
    }
}

.app_screenshots_slides * {
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s
}

.app_screenshots_slides .single-shot {
    opacity: 0.7;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=70)";
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
    text-align: center
}

.app_screenshots_slides .center .single-shot {
    opacity: 1;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.app-screenshots-area button.owl-dot {
    height: 11px;
    width: 11px;
    border: 2px solid #884bdf;
    border-radius: 50%;
    display: inline-block;
    margin: 0 6px;
    margin-top: 50px
}

.app-screenshots-area button.owl-dot.active {
    background-color: #fe4b8c;
    border-color: #fe4b8c
}

.app-screenshots-area .owl-dots {
    height: 100%;
    width: 100%;
    text-align: center;
    margin-top: 25px
}

.section-clients {
    padding: 50px 0
}

.clients img {
    opacity: .6;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s
}

.clients img:hover {
    opacity: 1
}

.item-title {
    vertical-align: middle
}

@media screen and (max-width: 991px) {
    .section-clients {
        padding: 30px 0
    }

    .section-clients .item {
        text-align: center;
        width: 100%
    }

    .section-clients .owl-carousel .owl-item img {
        max-width: 75%;
        display: inline-block
    }
}

.counter-box h3,
.counter-box h4 {
    display: inline-block;
    font-weight: 600
}

.social-icons a:hover {
    opacity: 1
}

.counter-box {
    position: relative;
    text-align: center
}

.counter-box h3:after {
    content: '+'
}

.counter-box h4 {
    font-size: 28px;
    line-height: normal;
    margin: 0
}

.counter-box h4:after {
    content: '+'
}

.counter-box h3 {
    font-size: 45px
}

@media screen and (max-width: 767px) {
    .counter-box p:before {
        margin: 0;
        position: absolute;
        top: 45px
    }

    .counter-box p:after {
        margin: 0;
        left: 14px;
        right: 0;
        top: 45px
    }

    .counter-box.black p:after {
        margin: 0;
        left: 14px;
        right: 0;
        top: 45px
    }

    .counter-box h4 {
        font-size: 22px
    }
}

.testmonial-single .avatar-info {
    overflow: hidden
}

.testmonial-single h6 {
    color: #29c2c1;
    font-weight: 500
}

.testmonial-single .avatar-info h4 {
    font-size: 18px;
    margin-bottom: 0;
    color: #29c2c1
}

.testmonial-single .avatar-info span {
    font-size: 13px;
    font-weight: bold
}

.testmonial-single .avatar-photo {
    display: inline-block;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    overflow: hidden;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    max-width: 130px;
    height: 130px;
    width: 100%;
    vertical-align: top;
    margin-top: 15px
}

.owl-carousel .owl-item .avatar-photo img {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-origin: center center;
    max-width: none;
    width: auto;
    margin-left: -30px;
    margin-top: -35px
}

.testmonial-single .item-inner {
    overflow: hidden;
    display: inline-block;
    vertical-align: top
}

.testmonials-style1 .custom-dot span {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    background: rgba(41, 45, 194, 0.15);
    border: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}

.testmonials-style1 .custom-dot span:hover {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    background: #29c2c1
}

@media screen and (max-width: 991px) {
    .testmonial-single .avatar-info h4 {
        font-size: 16px
    }
}

@media screen and (max-width: 767px) {
    .testmonials-style1 .custom-dot {
        margin-top: 10px
    }
}

.testmonial-style2 .author-details .location {
    font-style: italic
}

.testmonial-style2 .author-details span {
    display: block;
    text-align: left;
    font-weight: 600
}

.testmonial-style2 p {
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 50px;
    font-style: italic;
    margin-top: -115px
}

.testmonial-style2 .quote {
    font-size: 234px;
    opacity: 0.1;
    line-height: 210px
}

.testmonial-style2.owl-theme .owl-nav {
    margin-top: 0
}

.testmonial-style2.owl-theme .owl-nav .owl-next {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    -webkit-box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
    box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
    font-size: 20px;
    color: #222d39;
    border: 0px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    cursor: pointer;
    right: 0;
    padding: 0
}

.testmonial-style2.owl-theme .owl-nav .owl-prev {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff !important;
    -webkit-box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
    box-shadow: 0px 3px 4px 0px rgba(12, 0, 46, 0.06);
    font-size: 20px;
    color: #222d39;
    border: 0px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    cursor: pointer;
    left: 0;
    padding: 0
}

.testmonial-style2.owl-theme .owl-nav>div:hover {
    background: #29c2c1 !important;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.testmonial-style2.owl-theme .owl-nav>div:hover i {
    color: #fff
}

@media screen and (max-width: 1199px) {
    .testmonial-style2 p {
        font-size: 16px;
        line-height: 34px
    }

    .testmonial-style2 .quote {
        font-size: 214px;
        line-height: 200px
    }
}

@media screen and (max-width: 991px) {
    .testmonial-style2.owl-theme .owl-nav {
        margin-top: 0
    }

    .testmonial-style2 p {
        margin-bottom: 35px
    }
}

@media screen and (max-width: 767px) {
    .testmonial-style2 p {
        font-size: 15px;
        line-height: 32px
    }

    .testmonial-style2 .quote {
        font-size: 175px
    }
}

.testmonial-style3 .testmonial-block3 {
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 5px 24px 6px rgba(0, 0, 0, 0.06);
    padding: 45px 40px;
    margin: 30px 24px 40px;
    position: relative
}

.testmonial-style3 .testmonial-block3 p {
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 30px
}

.testmonial-style3 .quote {
    position: absolute;
    top: 30px;
    font-size: 124px;
    opacity: 0.2;
    line-height: 130px
}

@media screen and (max-width: 1199px) {
    .testmonial-style3 .testmonial-block3 {
        padding: 45px 25px
    }
}

@media screen and (max-width: 767px) {
    .testmonial-style3 .testmonial-block3 {
        padding: 35px 20px
    }
}

.testi-inner {
    margin: 0 6%;
    position: relative;
    z-index: 1;
    text-align: center;
    font-weight: 500
}

.testi-inner:before {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 35px;
    right: 35px;
    height: 200px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 12px 25px 0px rgba(0, 0, 0, 0.05);
    z-index: -1
}

.testi-inner:after {
    content: '';
    position: absolute;
    bottom: -55px;
    left: 80px;
    right: 80px;
    height: 200px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 15px 22px 0px rgba(0, 0, 0, 0.03);
    z-index: -3
}

.testmonial-block {
    background: #fff;
    padding: 60px 80px;
    box-shadow: 0px 16px 25px 0px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    position: relative;
    z-index: 5
}

.testmonial-block .quote {
    position: absolute;
    top: 140px;
    left: 0;
    right: 0;
    font-size: 220px;
    color: #f0f0f0
}

.testmonial-block p {
    font-size: 22px;
    line-height: 44px;
    font-style: italic;
    padding-bottom: 30px
}

.testmonial-carousel {
    margin-top: 70px
}

.testmonial-block h6 {
    font-weight: normal
}

.testmonial-block h6:before {
    content: "";
    width: 20px;
    height: 1px;
    background: #596982;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle
}

.testmonial-carousel.owl-theme .owl-nav {
    margin-top: 50px
}

.testmonial-carousel .owl-nav .owl-prev,
.testmonial-carousel .owl-nav .owl-next {
    background: transparent !important;
    box-shadow: none;
    width: auto;
    height: auto;
    margin: 0;
    font-size: 36px;
    color: #596982
}

.testmonial-carousel.owl-theme .owl-nav [class*='owl-']:hover {
    color: #243f67
}

.testmonial-carousel .owl-nav i {
    font-size: 36px;
    color: #9fa4b8;
    line-height: normal;
    padding: 0
}

.testmonial-block .author-details {
    text-align: center
}

@media screen and (max-width: 1199px) {
    .testmonial-block p {
        font-size: 20px;
        line-height: 40px
    }

    .testmonial-block .quote {
        font-size: 180px;
        top: 120px
    }
}

@media screen and (max-width: 991px) {
    .testmonial-block {
        padding: 50px 40px
    }

    .testmonial-block p {
        font-size: 18px;
        line-height: 36px;
        padding-bottom: 15px
    }

    .testmonial-carousel.owl-theme .owl-nav {
        margin-top: 30px
    }

    .testmonial-carousel .owl-nav i {
        font-size: 26px
    }

    .testmonial-carousel .owl-nav .owl-prev,
    .testmonial-carousel .owl-nav .owl-next {
        font-size: 32px
    }
}

@media screen and (max-width: 767px) {
    .testi-inner {
        margin: 0
    }

    .testmonial-block {
        padding: 30px 40px
    }

    .testmonial-block p {
        font-size: 16px;
        line-height: 34px;
        padding-bottom: 10px
    }

    .testmonial-block .quote {
        font-size: 150px;
        top: 100px
    }
}

@media screen and (max-width: 575px) {
    .testmonial-block .quote {
        top: 80px
    }

    .testi-inner {
        margin: 0
    }

    .testmonial-block {
        padding: 15px
    }

    .testmonial-block p {
        font-size: 15px;
        line-height: 30px
    }

    .testmonial-block .owl-item img {
        max-width: 70px
    }

    .testmonial-carousel.owl-theme .owl-nav {
        margin-top: 20px
    }
}

.custom-dot {
    display: inline-block;
    z-index: 1;
    margin-top: 20px
}

.custom-dot span {
    width: 12px;
    height: 12px;
    margin: 5px 5px 0 5px;
    background: none;
    cursor: pointer;
    display: block;
    border: 1px solid #6E6E6F;
    -webkit-backface-visibility: visible;
    -moz-backface-visibility: visible;
    -o-backface-visibility: visible;
    border-radius: 30px;
    transition-duration: .3s;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s
}

.custom-dot span:hover {
    background: #aaa;
    -webkit-backface-visibility: visible;
    -moz-backface-visibility: visible;
    -o-backface-visibility: visible;
    border-radius: 30px
}

.custom-dot.active span {
    width: 12px;
    height: 12px;
    background: #29c2c1;
    border-color: #29c2c1;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s
}

.owl-nav i {
    color: #232323;
    font-size: 18px;
    padding-top: 6px
}

.owl-nav .owl-next {
    color: #29c2c1;
    font-size: 43px;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #fff !important
}

.owl-nav .owl-prev {
    color: #29c2c1;
    font-size: 43px;
    text-align: center;
    width: 40px;
    height: 40px;
    background: #fff !important;
    left: 0;
    -webkit-transition: all .4s ease 0s;
    -moz-transition: all .4s ease 0s;
    -o-transition: all .4s ease 0s;
    transition: all .4s ease 0s;
    -webkit-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -o-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1)
}

.owl-nav .owl-next {
    -webkit-transition: all .4s ease 0s;
    -moz-transition: all .4s ease 0s;
    -o-transition: all .4s ease 0s;
    transition: all .4s ease 0s;
    -webkit-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -moz-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    -o-box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1);
    box-shadow: 0 5px 28px 0 rgba(50, 50, 50, 0.1)
}

@media screen and (max-width: 767px) {
    .custom-dot {
        margin-top: 15px
    }
}

.team-style {
    border: 1px dashed #cee1f8;
    border-radius: 10px;
    background: #fff;
    position: relative;
    transition: all 0.3s ease 0s
}

.team-style .team-member-img>img {
    max-width: 150px;
    padding: 6px;
    background: rgba(41, 45, 194, 0.15);
    border-radius: 100px
}

.team-style .social-links {
    margin-bottom: 0
}

.team-style .social-links li {
    display: inline-block;
    margin-right: 10px
}

.team-style .social-links li:last-child {
    margin-right: 0
}

.team-style .social-links li a:hover {
    opacity: 0.85
}

.team-style .bg-cover {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    border-radius: 10px;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease 0s
}

.team-style:hover .team-member-img {
    position: relative;
    z-index: 9
}

.team-style:hover .team-member-img>img {
    background: rgba(255, 255, 255, 0.15)
}

.team-style:hover .bg-cover {
    opacity: 1
}

.team-style:hover .alt-font {
    color: #fff
}

.team-style:hover .bg-theme {
    background: #fff
}

.team-style:hover div,
.team-style:hover .social-links a {
    color: #fff
}

@media screen and (max-width: 767px) {
    .team-style .team-member-img>img {
        max-width: 120px;
        padding: 4px
    }
}

.team-block {
    box-shadow: 0px 0px 16px 0px rgba(187, 187, 187, 0.48);
    background: #fff;
    overflow: hidden;
    transition: all .3s ease;
    z-index: 1;
    position: relative;
    border-radius: 4px
}

.team-block .team-img {
    overflow: hidden;
    position: relative
}

.team-block .team-img img {
    transition: all .7s ease-in-out
}

.team-block .team-icons {
    position: absolute;
    bottom: 35%;
    left: 0;
    right: 0;
    text-align: center;
    transition: opacity .3s, bottom .3s;
    display: block;
    opacity: 0
}

.team-block .team-icons i {
    background: #fff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 50px;
    margin-left: 10px
}

.team-block:hover .team-icons {
    opacity: 1
}

@media screen and (max-width: 767px) {
    .team-block .team-img img {
        width: 100%
    }

    .team-block .team-icons {
        bottom: 120px
    }
}

.team-style2 {
    background: #fff;
    overflow: hidden
}

.team-style2 .team-img {
    width: 100%;
    height: auto;
    border-radius: 4px
}

.team-style2 .team-img img {
    border-radius: 4px
}

.team-style2 .team-details {
    transition: all 0.4s ease-in-out;
    text-align: center;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.1);
    width: 90%;
    margin: -30px auto 20px auto;
    position: relative;
    z-index: 1;
    border-radius: 4px
}

.team-style2 .team-details h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 4px
}

.team-style2 .team-details h4 a {
    color: #232323;
    transition-duration: .0s;
    -ms-transition-duration: .0s;
    -moz-transition-duration: .0s;
    -webkit-transition-duration: .0s;
    -o-transition-duration: .0s
}

.team-style2 .team-details span {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px
}

@media screen and (max-width: 991px) {
    .team-style2 .team-details {
        padding: 15px 10px
    }

    .team-style2 .team-details h4 {
        font-size: 14px;
        margin-bottom: 2px
    }

    .team-style2 .team-details span {
        font-size: 14px
    }
}

@media screen and (max-width: 575px) {
    .team-style2 .team-details {
        padding: 15px 10px
    }
}

.filtering {
    margin-bottom: 10px
}

.filtering span {
    border-bottom: 1px solid transparent;
    color: #333333;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-right: 20px;
    display: inline-block;
    margin-bottom: 5px
}

.filtering span:last-child {
    margin: 0
}

.filtering .active {
    border-color: #29c2c1;
    color: #29c2c1
}

@media screen and (max-width: 767px) {
    .filtering span {
        margin-right: 15px
    }
}

.portfolio {
    overflow: hidden
}

.portfolio .filtering span {
    margin-right: 20px;
    color: #333;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 1px solid transparent
}

.portfolio .filtering span:last-child {
    margin: 0
}

.portfolio .filtering .active {
    border-color: #29c2c1;
    color: #29c2c1
}

.portfolio .items {
    margin-top: 20px
}

.portfolio .item-img {
    position: relative;
    overflow: hidden
}

.portfolio .item-img img {
    width: 100%
}

.portfolio .item-img:hover .item-img-overlay {
    opacity: 1;
    transform: translateY(0)
}

.portfolio .item-img:hover h6 {
    transform: translateY(0)
}

.portfolio .item-img h6 {
    font-weight: 600;
    position: relative;
    font-size: 16px;
    margin-bottom: 0;
    transform: translateY(-15px);
    transition: all 0.5s
}

.portfolio .item-img p {
    margin-bottom: 8px
}

.portfolio .item-img .fa {
    background: #29c2c1;
    border: 1px solid #29c2c1;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    height: 30px;
    line-height: 28px;
    width: 30px;
    transition: all 0.5s
}

.portfolio .item-img .fa:hover {
    background: #fff;
    color: #29c2c1
}

.portfolio .item-img-overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: rgba(255, 255, 255, 0.95);
    color: #35424C;
    opacity: 0;
    transition: all 0.5s;
    z-index: 2;
    transform: translateY(10px)
}

.project-item {
    position: absolute;
    left: 0;
    max-width: 35%;
    width: 100%;
    bottom: 7%;
    padding: 20px;
    background: rgba(255, 255, 255, 0.9) !important
}

.project-carousel h4:after {
    content: " ";
    background: #29c2c1;
    height: 2px
}

.project-single-text h4:after {
    content: " ";
    background: #29c2c1;
    height: 2px
}

.project-single-text p {
    font-weight: 400;
    line-height: 1.8
}

.project-single-text h5 {
    color: #232323;
    font-size: 18px;
    font-weight: 500;
    margin: 25px 0 20px
}

.project-single-text h5:after {
    display: block;
    width: 70px;
    margin-top: 8px;
    content: " ";
    background: #29c2c1;
    height: 2px
}

.project-single-text ul li {
    color: #232323;
    font-size: 15px;
    font-weight: 500
}

.project-single-text ul li i {
    color: #29c2c1;
    font-weight: 500;
    font-size: 15px;
    padding-right: 10px
}

.project-single-info {
    display: block;
    width: 100%
}

.project-single-info ul li {
    color: #777;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px dashed #cee1f8;
    padding: 12px 0;
    margin-right: 20px
}

.project-single-info ul li span {
    display: inline-block;
    color: #232323;
    font-size: 15px;
    font-weight: 500;
    margin-right: 25px;
    width: 70px;
    max-width: 100%
}

.project-single-info ul li .value {
    width: auto;
    margin-right: 0;
    color: #6f6f6f
}

.project-single-img {
    padding-bottom: 30px
}

.project-single-box-img img {
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
    -o-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07)
}

@media screen and (max-width: 767px) {
    .portfolio {
        overflow: hidden
    }

    .portfolio .filtering {
        margin-bottom: 5px
    }

    .portfolio .filtering span {
        margin-right: 15px;
        font-size: 12px
    }
}

.accordion-style {
    background: #fff;
    box-shadow: 0 1px 18px 10px rgba(0, 0, 0, 0.02);
    border-radius: 4px
}

.accordion-style .card .counts {
    display: inline-block;
    width: 30px;
    height: 30px;
    font-weight: 600;
    vertical-align: middle;
    line-height: 30px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    text-align: center;
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    color: #fff;
    font-size: 12px;
    margin-right: 10px
}

.accordion-style .card .item-title {
    display: inline-block;
    vertical-align: middle;
    width: 88%
}

.accordion-style .card-header:hover {
    background-color: #F3F8FF
}

.accordion-style .card {
    background: #fff;
    box-shadow: none;
    margin-top: 0 !important;
    border: none !important
}

.accordion-style .card:last-child {
    border: none
}

.accordion-style .card-header {
    border: 0px;
    padding: 0;
    transition: all 0.3s ease 0s;
    background: #fff
}

.accordion-style .btn-link {
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    color: #6f6f6f;
    display: block;
    text-decoration: none !important;
    margin: 0;
    cursor: pointer;
    padding: 15px 20px;
    border-bottom: 1px dashed #cee1f8;
    white-space: normal
}

.accordion-style .card:last-child .btn-link.collapsed {
    border-bottom: transparent
}

.accordion-style .btn-link {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    color: #fff;
    border-bottom: transparent;
    border-left: none !important
}

.accordion-style .btn-link.collapsed {
    box-shadow: none;
    background: none;
    color: #6f6f6f;
    border-bottom: 1px dashed #cee1f8
}

.accordion-style .btn:not(:disabled):not(.disabled).active,
.accordion-style .btn:not(:disabled):not(.disabled):active {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    color: #fff;
    border-bottom: transparent;
    border-left: none !important
}

.accordion-style .btn-link.collapsed .counts {
    background: transparent;
    border: 1px solid #cee1f8;
    color: #29c2c1
}

.accordion-style .btn-link .counts {
    background: #fff;
    border: 1px solid #cee1f8;
    color: #29c2c1
}

.accordion-style .btn:not(:disabled):not(.disabled).active .counts,
.accordion-style .btn:not(:disabled):not(.disabled):active .counts {
    background: #fff;
    border: 1px solid #cee1f8;
    color: #29c2c1
}

.accordion-style .card:last-child {
    border-bottom: 1px dashed #cee1f8
}

.accordion-style .card:last-child .show .card-body {
    border-bottom: transparent
}

.accordion-style .card-body {
    padding: 20px;
    border-bottom: 1px dashed #cee1f8;
    text-align: left
}

.accordion-style .btn:not(:disabled):not(.disabled):active {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

@media screen and (max-width: 991px) {
    .accordion-style .btn-link {
        font-size: 14px;
        padding: 12px 18px
    }

    .accordion-style .card .counts {
        margin-right: 8px
    }
}

@media screen and (max-width: 767px) {
    .accordion-style .card .item-title {
        width: 80%
    }

    .accordion-style .btn-link {
        font-size: 14px;
        padding: 12px 15px
    }
}

.accordion-style1 .card {
    background: transparent;
    box-shadow: none;
    margin-top: 0 !important;
    border: none
}

.accordion-style1 .card-header {
    border: 0px;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: none
}

.accordion-style1 .btn-link {
    background: #efefef !important;
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    color: #6f6f6f;
    position: relative;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    white-space: normal;
    border-radius: 4px;
    padding: 15px 50px 15px 15px;
    text-decoration: none;
    font-size: 15px
}

.accordion-style1 .btn-link:hover {
    border: none;
    text-decoration: none
}

.accordion-style1 .btn-link.collapsed {
    box-shadow: none
}

.accordion-style1 .btn-link.collapsed:after {
    background: none;
    border: 1px solid #4e54c8;
    border-radius: 50%;
    content: "+";
    right: 15px;
    left: inherit;
    font-size: 18px;
    line-height: 24px;
    height: 25px;
    transform: none;
    width: 25px;
    top: 15px;
    text-align: center;
    padding-left: 0;
    letter-spacing: -1px
}

.accordion-style1 .btn-link:after {
    background: none;
    border: 1px solid #4e54c8;
    border-radius: 50%;
    content: "-";
    right: 15px;
    left: inherit;
    font-size: 18px;
    height: 25px;
    line-height: 24px;
    transform: none;
    width: 25px;
    top: 15px;
    position: absolute;
    color: #4e54c8;
    text-align: center;
    padding-left: 2px;
    letter-spacing: -2px
}

.accordion-style1 .card-body {
    padding: 20px;
    line-height: 24px;
    text-align: left
}

@media screen and (max-width: 767px) {
    .accordion-style1 .btn-link {
        font-size: 14px;
        padding: 12px 50px 12px 15px
    }
}

ul.resp-tabs-list {
    margin: 0px;
    padding: 0px
}

.resp-tabs-list li {
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    padding: 8px 15px;
    margin: 0 4px 0 0;
    list-style: none;
    cursor: pointer;
    min-width: 180px;
    color: #000
}

.resp-tabs-list li:last-child {
    margin-right: 0
}

.resp-tabs-list li i {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: rgba(41, 45, 194, 0.15);
    text-align: center;
    font-size: 22px;
    border-radius: 50%;
    margin-bottom: 10px;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    color: #29c2c1
}

.resp-tabs-container {
    padding: 0px;
    background-color: #fff;
    clear: left
}

.resp-tab-content {
    display: none;
    padding: 50px 0 0 0
}

.resp-tabs-list li.resp-tab-active {
    padding: 15px 20px 13px 20px;
    color: #29c2c1;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    -o-border-top-right-radius: 5px;
    -webkit-border-radius-topleft: 5px;
    -moz-border-radius-topleft: 5px;
    -o-border-radius-topleft: 5px;
    -webkit-border-radius-topright: 5px;
    -moz-border-radius-topright: 5px;
    -o-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.resp-tabs-list li.resp-tab-active i {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9);
    color: #fff
}

.resp-content-active,
.resp-accordion-active {
    display: block
}

.resp-tab-content {
    border: 1px solid #c1c1c1;
    border-top-color: #c1c1c1;
    float: left;
    width: 100%
}

h2.resp-accordion {
    background: #fff !important;
    cursor: pointer;
    display: none;
    font-size: 14px;
    border: 1px solid #e4e4e4;
    border-top: 0px solid #e4e4e4;
    margin: 0px;
    padding: 15px 21px;
    float: left;
    width: 100%
}

h2.resp-tab-active {
    border-bottom: 0px solid #e4e4e4 !important;
    background: #29c2c1 !important;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9) !important;
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9) !important;
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9) !important;
    background: linear-gradient(-45deg, #29c2c1, #0084e9) !important;
    color: #fff
}

h2.resp-tab-title:last-child {
    border-bottom: 12px solid #e4e4e4 !important;
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

.resp-vtabs ul.resp-tabs-list {
    float: left;
    width: 30%
}

.resp-vtabs .resp-tabs-list li {
    display: block;
    padding: 15px 15px !important;
    margin: 0 0 4px;
    cursor: pointer;
    float: none;
    font-size: 18px
}

.resp-vtabs .resp-tabs-list li p {
    font-size: 16px
}

.resp-vtabs .resp-tabs-container {
    padding: 0px;
    background-color: #fff;
    border: 1px solid #29c2c1 !important;
    float: left;
    width: 70%;
    min-height: 250px;
    clear: none
}

.resp-vtabs .resp-tab-content {
    border: none;
    word-wrap: break-word;
    padding: 0
}

.resp-vtabs li.resp-tab-active {
    position: relative;
    z-index: 1;
    margin-right: -1px !important;
    padding: 14px 15px 15px 11px !important;
    border-top: 1px solid;
    border: 1px solid #29c2c1 !important;
    border-left: 4px solid #29c2c1 !important;
    border-right: 1px #FFF solid !important
}

.resp-arrow {
    border-color: transparent #232323 #232323 transparent;
    border-style: solid;
    border-width: 0 1px 1px 0;
    float: right;
    display: block;
    height: 8px;
    transform: rotate(45deg);
    width: 8px;
    margin-top: 4px
}

h2.resp-tab-active span.resp-arrow {
    border-color: #fff transparent transparent #fff;
    border-style: solid;
    border-width: 1px 0 0 1px;
    float: right;
    display: block;
    height: 8px;
    transform: rotate(45deg);
    width: 8px;
    margin-top: 7px
}

.resp-easy-accordion h2.resp-accordion {
    display: block
}

.resp-easy-accordion .resp-tab-content {
    border: 1px solid #c1c1c1
}

.resp-easy-accordion .resp-tab-content:last-child {
    border-bottom: 1px solid #c1c1c1
}

.resp-jfit {
    width: 100%;
    margin: 0px
}

.resp-tab-content-active {
    display: block;
    border-color: #e4e4e4 !important
}

h2.resp-accordion:first-child {
    border-top: 1px solid #e4e4e4
}

h2.resp-accordion.resp-tab-active {
    border-color: #e4e4e4 !important
}

@media screen and (min-width: 992px) {
    .tab-style ul.resp-tabs-list {
        text-align: center
    }

    .tab-style ul.resp-tabs-list.text-left {
        padding-left: 15px
    }

    .tab-style ul.resp-tabs-list li:last-child {
        margin-right: 0
    }

    .tab-style ul.resp-tabs-list li.resp-tab-active {
        color: #29c2c1;
        border-bottom: 3px solid #29c2c1
    }

    .tab-style .resp-tab-content {
        border-top: 1px dashed #cee1f8 !important;
        border-width: 1px 0 0 0;
        border-color: #29c2c1;
        margin-top: -2px
    }
}

@media screen and (max-width: 991px) {
    ul.resp-tabs-list {
        display: none
    }

    h2.resp-accordion {
        display: block
    }

    h2.resp-accordion i {
        margin-right: 12px;
        font-size: 18px;
        min-width: 25px
    }

    h2.resp-accordion.resp-tab-active i {
        color: #fff;
        display: inline-block;
        vertical-align: top
    }

    .resp-vtabs .resp-tab-content {
        border: 1px solid #e4e4e4
    }

    .resp-vtabs .resp-tabs-container {
        border: none !important;
        float: none;
        width: 100%;
        min-height: 100px;
        clear: none
    }

    .resp-accordion-closed {
        display: none !important
    }

    .resp-vtabs .resp-tab-content:last-child {
        border-bottom: 1px solid #e4e4e4 !important
    }

    .resp-tab-content {
        background-color: #fff;
        padding: 20px 15px
    }
}

.tab-style1 ul.resp-tabs-list {
    text-align: center
}

.tab-style1 .resp-tabs-list li {
    color: #29c2c1;
    border: 2px solid transparent;
    background: rgba(41, 45, 194, 0.15);
    margin: 0;
    padding: 15px 20px 13px 20px
}

.tab-style1 .resp-tabs-list li:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0
}

.tab-style1 .resp-tabs-list li:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0
}

.tab-style1 .resp-tabs-list li.resp-tab-active {
    color: #fff;
    background: #29c2c1
}

.tab-style1 .resp-tab-content {
    border: none
}

@media screen and (max-width: 1199px) {
    .tab-style1 .resp-tabs-list li {
        padding: 10px;
        min-width: 150px
    }
}

@media screen and (max-width: 991px) {
    .tab-style1 .resp-tab-content {
        border: 1px solid #d5d5d5;
        padding: 40px 35px
    }

    .tab-style1 h2.resp-tab-active {
        background: #29c2c1 !important
    }
}

@media screen and (max-width: 575px) {
    .tab-style1 .resp-tab-content {
        padding: 25px 15px
    }
}

.vtab-style1 .resp-tabs-list li.resp-tab-active {
    background: #29c2c1;
    background: -webkit-linear-gradient(180deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(180deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(180deg, #29c2c1, #0084e9);
    background: linear-gradient(180deg, #29c2c1, #0084e9);
    padding: 15px 20px 13px 20px;
    color: #29c2c1;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.vtab-style1.resp-vtabs ul.resp-tabs-list {
    width: auto;
    float: unset
}

.vtab-style1.resp-vtabs .resp-tabs-list li {
    -webkit-box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.08);
    margin-bottom: 35px;
    padding: 20px 35px !important;
    color: #232323
}

.vtab-style1.resp-vtabs .resp-tabs-list li:last-child {
    margin-bottom: 0 !important
}

.vtab-style1.resp-vtabs .resp-tabs-container {
    width: auto;
    float: unset;
    border: none !important
}

.vtab-style1.resp-vtabs li.resp-tab-active {
    color: #fff;
    border: none !important;
    border-left: none !important;
    border-radius: 4px !important
}

.vtab-style1.resp-tab-content {
    padding-top: 0
}

.vtab-style1 .resp-tabs-list li.resp-tab-active .icon-circle {
    background: white
}

.vtab-style1 .resp-tabs-list .tab-desc {
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    max-width: 71%;
    width: 100%
}

.vtab-style1 .icon-circle {
    vertical-align: middle
}

@media screen and (max-width: 1199px) {
    .vtab-style1.resp-vtabs .resp-tabs-list li {
        margin-bottom: 25px;
        padding: 20px !important
    }

    .vtab-style1.resp-vtabs .icon-circle {
        width: 65px;
        height: 65px;
        line-height: 75px
    }
}

@media screen and (max-width: 991px) {
    .vtab-style1.resp-vtabs .resp-tabs-container h2.resp-tab-active {
        background: #29c2c1 !important;
        background: -webkit-linear-gradient(180deg, #29c2c1, #0084e9) !important;
        background: -moz-linear-gradient(180deg, #29c2c1, #0084e9) !important;
        background: -o-linear-gradient(180deg, #29c2c1, #0084e9) !important;
        background: linear-gradient(180deg, #29c2c1, #0084e9) !important;
        color: #29c2c1;
        -webkit-border-top-left-radius: 0;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topleft: 0;
        -moz-border-radius-topright: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0
    }

    .vtab-style1.resp-vtabs .resp-tab-content {
        padding: 20px 20px 40px 20px
    }

    .vtab-style1 .resp-accordion.resp-tab-active .icon-circle {
        background: white
    }

    .vtab-style1 .resp-accordion .tab-desc {
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;
        max-width: 71%;
        width: 100%;
        font-size: 16px
    }

    .vtab-style1 .resp-accordion.resp-tab-active .tab-desc {
        color: #fff
    }
}

@media screen and (max-width: 767px) {
    .vtab-style1 .resp-accordion .tab-desc {
        max-width: 65%;
        font-size: 15px
    }
}

.single-plan {
    position: relative;
    z-index: 9;
    border: 1px solid #efefef;
    background: #fff;
    -webkit-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    -moz-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    -ms-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    -o-box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05)
}

.single-plan .head-plan {
    border-bottom: 1px dashed #cee1f8
}

.single-plan .head-plan i {
    background: rgba(41, 45, 194, 0.15);
    color: #29c2c1;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 32px;
    border-radius: 50%;
    margin-top: 30px
}

.single-plan .head-plan h4 {
    padding: 20px;
    margin: 0;
    color: #000;
    font-weight: 600
}

.body-plan .price-plan {
    padding: 20px 0;
    position: relative;
    font-weight: bold
}

.body-plan .price-plan h3 {
    margin: 0;
    z-index: 11;
    position: relative;
    font-weight: bold;
    color: #6f6f6f
}

.body-plan .price-plan h3 sup {
    font-size: 18px;
    top: -15px;
    font-weight: bold;
    margin-right: 2px
}

.body-plan .price-plan span {
    text-transform: uppercase;
    font-size: 12px;
    z-index: 11;
    position: relative
}

.feat-plan {
    margin: 0 0 30px
}

.feat-plan ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.feat-plan ul li {
    margin-bottom: 15px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px
}

.feat-plan ul li:last-child {
    margin: 0
}

.price-plan i {
    color: #fff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    border-radius: 50%;
    position: absolute;
    bottom: -24px;
    left: 42.6%;
    z-index: 99;
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

.footer-plan {
    padding: 0 0 30px
}

@media screen and (max-width: 991px) {
    .single-plan .head-plan i {
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 28px;
        margin-top: 20px
    }

    .single-plan .head-plan h4 {
        padding: 15px
    }

    .body-plan .price-plan h3 sup {
        font-size: 16px
    }

    .footer-plan {
        padding-bottom: 20px
    }

    .feat-plan {
        margin: 0 0 20px
    }

    .body-plan .price-plan {
        padding: 20px 0 10px 0
    }
}

@media screen and (max-width: 767px) {
    .single-plan .head-plan i {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 24px
    }

    .body-plan .price-plan h3 sup {
        font-size: 14px
    }
}

.price .item {
    padding: 0 0 50px 0;
    transition: all .3s;
    background: #fff
}

.price .item:hover {
    box-shadow: 0px 5px 40px 0px rgba(148, 146, 245, 0.2)
}

.price.price-style1 .item {
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05)
}

.price .type {
    padding-top: 45px;
    position: relative
}

.price .type i {
    background: #ffffff;
    border: 1px solid #29c2c1;
    border-radius: 100%;
    color: #29c2c1;
    display: inline-block;
    font-size: 30px;
    height: 80px;
    line-height: 78px;
    margin-bottom: 15px;
    width: 80px
}

.type .top-btn {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -20px
}

.price .type h4 {
    color: #232323;
    font-weight: 700;
    text-transform: uppercase
}

.price .value {
    position: relative
}

.price .value h3 {
    display: inline-block;
    font-size: 40px;
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    color: #6f6f6f
}

.price .value h3 span {
    font-size: 18px;
    position: absolute;
    top: -2px;
    left: -12px;
    color: #6f6f6f
}

.price .value .per {
    font-size: 13px;
    color: #6f6f6f
}

.price .features li {
    padding: 20px 0;
    font-weight: 600;
    font-size: 15px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2)
}

.price .order {
    padding-top: 15px;
    position: relative
}

.price .item.active {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

.price .item.active h3,
.price .item.active h4 {
    color: #fff
}

.price .item.active .value h3 span,
.price .item.active .value .per {
    color: #fff
}

.price .item.active .features li {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
    color: #fff
}

.price .item.active.bg-theme-90 {
    background: #29c2c1;
    background: -webkit-linear-gradient(90deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(90deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(90deg, #29c2c1, #0084e9);
    background: linear-gradient(90deg, #29c2c1, #0084e9)
}

.atividades-table {
    width: 100%;
    margin: 60px 0;
    border: 1px solid #ddd;
}

.atividades-table th {
  
    background-color: #ddd;
}

.atividades-table td, .atividades-table th {
    padding: 10px;
    border: 1px solid #ddd;
}

@media screen and (max-width: 1199px) {
    .price .value h3 {
        font-size: 36px
    }
}

@media screen and (max-width: 991px) {
    .price .item {
        padding-bottom: 40px
    }

    .price .type {
        padding-top: 35px
    }

    .price .features li {
        padding: 15px 0;
        font-size: 14px
    }
}

@media screen and (max-width: 767px) {
    .price .item {
        padding-bottom: 30px
    }

    .price .value h3 {
        font-size: 32px
    }

    .price .value h3 span {
        font-size: 13px;
        top: 0px;
        left: -10px
    }

    .price .value .per {
        font-size: 12px
    }
}

.price-table {
    text-align: center;
    background: #fff;
    padding: 55px 55px 140px 55px;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    height: 100%;
    margin-bottom: 30px;
    position: relative;
    background: #fff;
    box-shadow: 0px 3px 10px 0px rgba(41, 45, 194, 0.08);
    border-radius: 4px;
    margin: 0px 10px 0px 0px
}

.price-table .price-header {
    text-align: center
}

.price-table .price-header .price {
    font-size: 36px;
    margin-bottom: 30px;
    font-weight: 600;
    letter-spacing: 2px
}

.price-table .price-header .title {
    font-size: 28px;
    padding-bottom: 15px;
    margin-bottom: 15px
}

.price-table .price-header .package {
    font-size: 16px;
    padding-bottom: 10px;
    margin-bottom: 10px
}

.price-table .price-body {
    border-top: 1px dashed #d8dde1;
    padding-top: 40px
}

.price-table .price-body .feature {
    font-size: 20px;
    color: #303858;
    padding-bottom: 15px
}

.price-table .price-body ul li {
    font-size: 16px;
    position: relative;
    line-height: 40px;
    display: block;
    padding-bottom: 5px
}

.price-table .price-footer {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%
}

.price-table .trial-button {
    display: block;
    text-align: center;
    color: #818386;
    margin-top: 32px
}

@media screen and (max-width: 1199px) {
    .price-table {
        padding: 35px 35px 120px 35px
    }

    .price-table .price-header .title {
        margin-bottom: 10px;
        padding-bottom: 10px
    }

    .price-table .price-header .price {
        margin-bottom: 25px;
        font-size: 34px
    }
}

@media screen and (max-width: 991px) {
    .price-table {
        margin-right: 0
    }

    .price-table .price-header .title {
        padding-bottom: 0
    }

    .price-table .price-header .price {
        margin-bottom: 20px;
        font-size: 30px
    }

    .price-table .price-body {
        padding-top: 30px
    }
}

@media screen and (max-width: 575px) {
    .price-table {
        padding: 25px 15px 120px 15px
    }

    .price-table .price-header .price {
        margin-bottom: 15px;
        font-size: 26px
    }

    .price-table .price-header .package {
        font-size: 15px
    }
}

.pricing-list {
    text-align: center;
    border-radius: 10px;
    background: #fff;
    border: 2px solid #ececec;
    padding: 30px 40px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    -webkit-transition-duration: .5s;
    transition-duration: .5s
}

.pricing-list.highlight {
    background: #29c2c1;
    border-color: transparent
}

.pricing-list.highlight .pricing-list-button {
    background: #fff;
    margin: 20px 15px 0;
    border: 2px solid #fff;
    border-radius: 5px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.pricing-list.highlight .pricing-list-button:hover {
    background: #29c2c1
}

.pricing-list.highlight .pricing-list-button a {
    display: block;
    color: #29c2c1;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.pricing-list.highlight .pricing-list-button:hover a {
    color: #fff
}

.pricing-list h4 {
    color: #232323;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 20px
}

.pricing-list h2 {
    display: inline-block;
    color: #232323;
    letter-spacing: 2px
}

.pricing-list h2 sup {
    color: #232323;
    font-size: 30px;
    font-weight: 500;
    vertical-align: sub
}

.pricing-list span {
    display: inline-block;
    color: #29c2c1;
    font-weight: 500
}

.pricing-list ul {
    margin-top: 20px
}

.pricing-list ul li {
    color: #666;
    font-size: 15px;
    font-weight: 400;
    padding: 7px
}

.pricing-list ul li:after {
    content: '';
    display: block;
    width: 240px;
    max-width: 100%;
    height: 1px;
    background: #ececec;
    margin: 10px auto 0
}

.pricing-list-button {
    background: #29c2c1;
    margin: 20px 15px 0;
    border: 2px solid #29c2c1;
    border-radius: 5px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.pricing-list-button:hover {
    background: #fff
}

.pricing-list-button a {
    display: block;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.pricing-list-button:hover a {
    color: #29c2c1
}

.pricing-list>i {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 100%;
    color: #29c2c1;
    display: inline-block;
    font-size: 30px;
    height: 80px;
    line-height: 78px;
    margin-bottom: 15px;
    width: 80px
}

.widget-block {
    border: 1px solid #f5f5f5;
    -webkit-box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.04);
    box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.04);
    padding: 40px;
    margin-bottom: 50px;
    word-break: break-word
}

.widget-block:last-child,
.widget-block:last {
    margin-bottom: 0
}

.widget-block h4 {
    font-size: 22px;
    color: #29c2c1;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
    margin-bottom: 35px
}

.widget-block .list-style-icon {
    margin-bottom: 0;
    padding-bottom: 0
}

.widget-block .list-style-icon li {
    border-bottom: 1px solid #e4e4e4;
    color: #596982;
    padding-left: 35px;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 15px
}

.widget-block .list-style-icon li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.widget-block .list-style-icon li i {
    position: absolute;
    left: 0;
    top: 2px;
    color: #29c2c1;
    font-size: 20px
}

.widget-block .list-style-icon li h6 {
    font-size: 16px;
    margin-bottom: 5px;
    color: #243f67
}

.widget-block .list-style-icon li p {
    margin-bottom: 0;
    color: #596982
}

@media screen and (max-width: 1199px) {
    .widget-block {
        margin-bottom: 40px;
        padding: 35px
    }
}

@media screen and (max-width: 767px) {
    .widget-block h4 {
        font-size: 20px;
        margin-bottom: 30px
    }

    .widget-block .list-style-icon li {
        margin-bottom: 25px;
        padding-bottom: 13px
    }
}

.case-study-block {
    border: 1px solid #f5f5f5;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease
}

.case-study-block:hover {
    -webkit-box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.1)
}

.case-study-block h4 a {
    color: #243f67
}

.case-study-block strong {
    color: #596982
}

.job-content {
    display: table;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0 2px 8px 0 rgba(12, 0, 46, 0.04);
    box-shadow: 0 2px 8px 0 rgba(12, 0, 46, 0.04);
    padding: 20px 30px 20px 30px;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
    border-radius: 4px
}

.job-content h4 a {
    color: #243f67
}

.job-content ul li {
    color: #596982
}

@media screen and (max-width: 767px) {
    .job-content {
        padding: 20px
    }
}

.search-form_input {
    color: #29c2c1
}

.search-frame h4 a:hover {
    color: #29c2c1
}

.search-frame .search_list .match {
    color: #29c2c1
}

.search-frame .search_list li:before {
    color: #29c2c1
}

.search-frame .search_list li+li {
    border-top: 3px solid #29c2c1
}

.search-frame .search {
    color: #29c2c1
}

.common-form {
    border: 20px solid #fff;
    background: #fbfbfd;
    padding: 60px 50px;
    -webkit-box-shadow: 0px 50px 100px 0px rgba(12, 0, 46, 0.05);
    box-shadow: 0px 50px 100px 0px rgba(12, 0, 46, 0.05)
}

@media screen and (max-width: 991px) {
    .common-form {
        padding: 40px 30px;
        border: 15px solid #fff
    }
}

@media screen and (max-width: 575px) {
    .common-form {
        padding: 35px 25px;
        border: 10px solid #fff
    }
}

.page-container .title {
    font-size: 250px;
    line-height: 1;
    margin-bottom: 0
}

@media screen and (max-width: 1199px) {
    .page-container .title {
        font-size: 200px
    }
}

@media screen and (max-width: 991px) {
    .page-container .title {
        font-size: 125px
    }
}

@media screen and (max-width: 767px) {
    .page-container .title {
        font-size: 75px
    }
}

.coming-soon .social-links li {
    display: inline-block;
    font-size: 22px;
    margin-right: 30px
}

.coming-soon .social-links li:last-child {
    margin-right: 0
}

.coming-soon .social-links li a {
    color: #fff
}

.coming-soon .social-links li a:hover {
    color: rgba(255, 255, 255, 0.65)
}

ul.countdown li {
    border-right: 1px solid #c5c5c5;
    display: inline-block;
    padding: 0 30px;
    text-align: center
}

ul.countdown li:last-child {
    border: medium none;
    padding-right: 0
}

ul.countdown li span {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    position: relative
}

ul.countdown li span::before {
    content: "";
    height: 1px;
    position: absolute;
    width: 100%
}

ul.countdown li p.timeRefDays,
ul.countdown li p.timeRefHours,
ul.countdown li p.timeRefMinutes,
ul.countdown li p.timeRefSeconds {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-transform: uppercase
}

@media screen and (max-width: 767px) {
    .coming-soon .social-links li {
        margin-right: 15px;
        font-size: 16px
    }

    ul.countdown li {
        padding: 0 8px
    }

    .social-links li {
        margin-right: 15px
    }

    ul.countdown li span {
        font-size: 22px
    }
}

.blog-grid {
    position: relative;
    background: #fff;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out
}

.blog-grid span {
    color: #29c2c1
}

.blog-grid img {
    width: 100%
}

.data-box-grid {
    display: inline-block;
    left: 0;
    bottom: -1px;
    padding: 5px 20px;
    font-size: 18px;
    -webkit-border-top-right-radius: 7px;
    -moz-border-top-right-radius: 7px;
    -o-border-top-right-radius: 7px;
    -webkit-border-radius-topright: 7px;
    -moz-border-radius-topright: 7px;
    -o-border-radius-topright: 7px;
    border-top-right-radius: 7px;
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

.data-box-grid h5 {
    letter-spacing: 1px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    margin-right: 8px;
    color: #fff;
    font-size: 20px
}

.blog-grid .data-box-grid p {
    font-size: 14px;
    margin-bottom: 0;
    padding: 0;
    border: none;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase
}

.blog-grid-text {
    position: relative;
    -moz-box-shadow: 0 0 10px #cccccc;
    -webkit-box-shadow: 0 0 10px #cccccc;
    -o-box-shadow: 0 0 10px #cccccc;
    box-shadow: 0 0 10px #cccccc
}

.blog-grid-text>span {
    color: #29c2c1;
    font-size: 13px;
    padding-right: 5px
}

.blog-grid-text h4 {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 15px
}

.blog-grid-text .meta-style2 {
    border-top: 1px dashed #cee1f8;
    padding-top: 15px
}

.blog-grid-text .meta-style2 ul li {
    margin-bottom: 0;
    font-weight: 500
}

.blog-grid-text ul {
    margin: 0;
    padding: 0
}

.blog-grid-text ul li {
    display: inline-block;
    color: #999;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 10px 5px 0
}

.blog-grid-text ul li i {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px
}

.blog-grid-text p {
    color: #999;
    font-weight: 400;
    line-height: 170%;
    padding: 0
}

.blog-list-left-heading:after,
.blog-title-box:after {
    content: '';
    height: 2px
}

.blog-grid-simple-content a:hover {
    color: #232323
}

.blog-grid-simple-content a:hover:after {
    color: #232323
}

.blog-list-simple {
    margin: 0 0 30px 0;
    padding-bottom: 30px
}

.blog-list-simple:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0
}

.blog-list-simple-text {
    color: #999999
}

.blog-list-simple-text>span {
    color: #29c2c1;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px
}

.blog-list-simple-text p {
    color: #999999;
    font-size: 15px;
    font-weight: 400;
    line-height: 170%;
    margin-bottom: 0;
    padding: 15px 0
}

.blog-list-simple-text .meta {
    margin: 0 0 5px 0
}

.blog-list-simple-text li {
    display: inline-block;
    font-size: 12px;
    color: #777;
    margin: 5px
}

.blog-list-simple-text h4 {
    color: #232323;
    font-size: 20px;
    font-weight: 500;
    margin: 5px 0;
    line-height: normal
}

.blog-list-simple-text ul {
    margin: 10px 0
}

.blog-list-simple-text ul li {
    color: #999;
    font-size: 14px;
    font-weight: 500;
    padding: 3px 0
}

.blog-list-simple-text ul li i {
    color: #29c2c1;
    margin-right: 5px;
    font-size: 14px
}

.blog-block {
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    border-radius: 5px
}

.blog-block .blog-text {
    border-bottom: 1px solid #ececec
}

.blog-block .blog-img {
    position: relative;
    overflow: hidden
}

.blog-block .blog-bottom-holder img {
    max-width: 42px
}

.blogs .post {
    margin-bottom: 50px
}

.blogs .post .content {
    padding: 30px;
    background: #fafafa
}

.blogs .post .content .post-title h5 {
    font-size: 26px;
    line-height: normal;
    font-weight: 500;
    margin-bottom: 0
}

.blogs .post .content .post-title h5 a {
    color: #232323
}

.blogs .post .content .post-title h5 a:hover {
    color: #F24259
}

.blogs .post .content .meta {
    margin-bottom: 15px
}

.blogs .post .content .meta li {
    display: inline-block;
    font-size: 12px;
    color: #777;
    margin: 5px 5px 0 5px
}

.blogs .post .content .special {
    padding: 15px;
    margin: 30px 0;
    border-left: 2px solid #111;
    background: #f7f7f7;
    font-size: 16px
}

.blogs .post .content .btn {
    border-color: #6f6f6f;
    color: #6f6f6f;
    min-width: auto;
    padding: 6px 20px;
    font-size: 12px
}

.blogs .post .content .btn:hover {
    color: #fff;
    border-color: #F24259
}

.blogs .post .share-post {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px dashed #ddd;
    text-align: left
}

.blogs .post .share-post span {
    font-weight: 700
}

.blogs .post .share-post ul {
    float: right
}

.blogs .post .share-post ul li {
    display: inline-block;
    margin: 0 10px
}

.blogs .post .post-img img {
    width: 100%
}

.blogs .posts .title-g h3 {
    font-weight: 700;
    font-size: 25px;
    margin-bottom: 10px
}

.blogs .comments-area .comment-box {
    padding-bottom: 30px;
    margin-bottom: 50px;
    border-bottom: 1px solid #eee
}

.blogs .comments-area .comment-box:nth-child(odd) {
    margin-left: 80px
}

.blogs .comments-area .comment-box:last-child {
    margin-bottom: 30px
}

.blogs .comment-box .author-thumb {
    width: 80px;
    float: left
}

.blogs .comment-box .comment-info {
    margin-left: 100px
}

.blogs .comment-box .comment-info h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px
}

.blogs .comment-box .comment-info .reply {
    margin-top: 10px;
    font-weight: 600
}

.blogs .comment-box .comment-info .reply i {
    padding-right: 5px;
    font-size: 12px
}

.pagination {
    border-radius: 0;
    padding: 0;
    margin: 0
}

.pagination ul {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 auto;
    padding: 0
}

.pagination li {
    display: inline
}

.pagination a {
    float: left;
    padding: 0 18px;
    line-height: 40px;
    text-decoration: none;
    border: 1px solid #dbdbdb;
    border-left-width: 0;
    background: #fff
}

.pagination a:hover {
    background-color: #232323;
    color: #fff
}

.pagination .active a {
    background-color: #f7f7f7;
    color: #999;
    cursor: default
}

.pagination .disabled span {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination .disabled a {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination .disabled a:hover {
    color: #999;
    background-color: transparent;
    cursor: default
}

.pagination li:first-child a {
    border-left-width: 1px
}

.blockquote {
    background: #f1f1f1;
    border-left: 4px solid #29c2c1;
    font-size: 16px;
    font-weight: 500;
    margin: 25px 0;
    padding: 20px 30px 30px
}

.blockquote p {
    font-size: 15px;
    font-weight: 500;
    line-height: 190%;
    color: #232323;
    letter-spacing: .5px
}

.blockquote h4 {
    font-size: 20px;
    font-weight: 400;
    color: #232323;
    letter-spacing: .5px
}

.side-bar .widget {
    margin-bottom: 30px
}

.side-bar .widget:last-child {
    margin-bottom: 0
}

.side-bar .widget .widget-title h6 {
    position: relative;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 20px
}

.side-bar .widget .widget-title h6:after {
    content: '';
    width: 30px;
    height: 1px;
    background: #29c2c1;
    position: absolute;
    bottom: 0;
    left: 0
}

.side-bar .widget li {
    position: relative;
    padding: 0 0 0 12px;
    margin: 0 0 9px 0;
    color: #6f6f6f
}

.side-bar .widget li:last-child {
    margin: 0
}

.side-bar .widget li:after {
    content: "";
    width: 5px;
    height: 1px;
    background-color: #6f6f6f;
    position: absolute;
    top: 12px;
    left: 0
}

.side-bar .widget .social-listing {
    margin-bottom: 0
}

.side-bar .widget .social-listing li {
    list-style: none;
    display: inline-block
}

.side-bar .widget .social-listing li:after {
    background: none
}

.side-bar .widget .social-listing li:first-child {
    padding-left: 0
}

.side-bar .search form input {
    width: calc(100% - 50px);
    height: 50px;
    padding: 0 10px;
    margin: 0;
    border: 1px solid #d1d1d1;
    background: #f7f7f7;
    min-height: auto
}

.side-bar .search form button {
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    border: 0;
    float: right;
    border-radius: 0;
    padding: 0
}

.side-bar .search form button:hover:before {
    border-radius: 0;
    padding: 0
}

@media screen and (max-width: 1199px) {
    .blog-grid-text h4 {
        font-size: 15px
    }

    .blogs .post .content .post-title h5 {
        font-size: 22px
    }

    .blogs .post .content .special {
        font-size: 16px
    }

    .blogs .posts .title-g h3 {
        font-size: 22px
    }
}

@media screen and (max-width: 767px) {
    .blog-grid-text h4 {
        font-size: 14px
    }

    .blog-list-simple {
        margin-bottom: 20px;
        padding-bottom: 20px
    }

    .data-box-grid {
        padding: 5px 15px;
        font-size: 16px
    }

    .data-box-grid h5 {
        font-size: 16px;
        margin-right: 3px
    }

    .blog-list-simple-text>span {
        font-size: 12px
    }

    .blog-list-simple-text p {
        font-size: 14px
    }

    .blog-list-simple-text h4 {
        font-size: 18px
    }

    .blog-list-simple-text ul li {
        font-size: 12px
    }

    .blogs .post .content .post-title h5 {
        font-size: 20px
    }

    .blogs .post .content .special {
        font-size: 14px
    }

    .blogs .posts .title-g h3 {
        font-size: 18px
    }
}

.social-icon-style1 {
    margin-bottom: 0
}

.social-icon-style1 li {
    text-align: center;
    margin-right: 4px;
    display: inline-block
}

.social-icon-style1 li a {
    background: #29c2c1;
    border: 1px solid #29c2c1;
    color: #fff;
    border-radius: 35px;
    height: 35px;
    width: 35px;
    line-height: 35px;
    display: inline-block
}

.social-icon-style1 li a:hover {
    background: #fff;
    color: #29c2c1
}

.social-icon-style2 li {
    display: inline-block;
    margin-right: 30px
}

.social-icon-style2 li:last-child {
    margin-right: 0
}

.social-icon-style2 li a {
    font-size: 22px
}

.social-icon-style2 li a:hover {
    color: #fff
}

.contact-info .item {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2)
}

.contact-info .item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.contact-info .item .icon {
    font-size: 20px;
    float: left;
    width: 50px;
    text-align: center
}

.contact-info .item .cont {
    margin-left: 55px
}

.contact-info .item .cont h6 {
    font-size: 15px;
    margin-bottom: 0
}

.contact-info .item .cont p {
    margin: 0
}

.newsletter {
    position: relative;
    background: none;
    overflow: hidden
}

.newsletter input {
    width: 99%;
    height: 45px;
    min-height: auto;
    margin-bottom: 0;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px;
    border: 1px solid rgba(78, 78, 84, 0.2);
    outline: none;
    padding-left: 20px;
    padding-right: 120px;
    z-index: 1;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.67px
}

.newsletter .butn {
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 2;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    outline: none;
    cursor: pointer;
    padding: 13px 18px;
    border-top-left-radius: 0;
    box-shadow: none;
    border-bottom-left-radius: 0
}

.newsletter .butn:hover:before,
.newsletter .butn:focus:before {
    padding: 14px 18px 14px 18px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.newsletter .input .butn:active:before {
    padding: 14px 18px 14px 18px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

#map {
    height: 400px;
    width: 100%
}

.quick-contact .form-input {
    padding-left: 5px;
    border-width: 0 0 1px 0
}

@media screen and (max-width: 991px) {
    .contact-info .item {
        margin-bottom: 20px;
        padding-bottom: 20px
    }
}

@media screen and (max-width: 767px) {
    .contact-info .item {
        margin-bottom: 15px;
        padding-bottom: 15px
    }

    .social-icon-style2 li a {
        font-size: 18px
    }
}

footer {
    padding: 50px 0 0;
    background: #1f1f1f;
    color: #939393
}

footer p {
    margin-bottom: 0
}

footer address {
    margin-bottom: 5px
}

footer h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    line-height: 1;
    position: relative
}

footer h3:after {
    content: '';
    display: block;
    width: 70px;
    height: 2px;
    background: #29c2c1;
    margin: 12px auto 0 auto
}

.footer-title-style:after {
    margin-left: auto;
    margin-right: auto
}

h3.footer-title-style {
    margin-bottom: 20px
}

footer h3.footer-title-style2 {
    margin-bottom: 28px
}

h3.footer-title-style2:after {
    width: 100%;
    border-bottom: 1px dashed #999;
    margin-top: 15px;
    background: none
}

footer h4.footer-title-style3 {
    margin-bottom: 30px;
    font-size: 24px
}

.footer-bar {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 50px;
    text-align: center;
    background: #191919;
    color: #939393
}

.footer-bar span {
    color: #29c2c1;
    font-size: 15px;
    font-weight: 400
}

@media screen and (max-width: 1199px) {
    footer h4.footer-title-style3 {
        font-size: 22px;
        margin-bottom: 25px
    }
}

@media screen and (max-width: 991px) {
    footer h4.footer-title-style3 {
        font-size: 20px;
        margin-bottom: 20px
    }
}

@media screen and (max-width: 767px) {
    footer {
        padding-top: 30px
    }

    footer h3 {
        font-size: 16px
    }

    h3.footer-title-style {
        margin-bottom: 15px
    }

    footer h3.footer-title-style2 {
        margin-bottom: 20px
    }

    .footer-bar {
        margin-top: 30px
    }

    .footer-bar p {
        font-size: 13px
    }
}

.footer-style2 {
    box-shadow: 0px 0 75px 0 rgba(0, 0, 0, 0.05)
}

.footer-style2>.container {
    padding: 120px 15px
}

@media screen and (max-width: 1199px) {
    .footer-style2>.container {
        padding: 90px 15px
    }
}

@media screen and (max-width: 991px) {
    .footer-style2>.container {
        padding: 70px 15px
    }
}

.footer-style3 {
    padding-top: 240px;
    margin-top: -120px;
    z-index: 0
}

.footer-style3 .footer-icon ul li {
    display: inline-block;
    margin-right: 20px
}

.footer-style3 ul li {
    margin: 10px 0;
    font-size: 16px
}

.footer-style3 ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0
}

.footer-style3 h3:after {
    content: "";
    background: unset;
    margin: 0
}

.footer-style3-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
    padding: 20px 0;
    margin-top: 100px
}

.footer-style3 h4 {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-weight: 500
}

.footer-style3 a {
    color: #939393
}

.footer-style3 a:hover {
    color: #fff
}

@media screen and (max-width: 1199px) {
    .footer-style3 {
        padding-top: 220px;
        margin-top: -120px
    }

    .footer-style3-bottom {
        margin-top: 70px
    }
}

@media screen and (max-width: 991px) {
    .footer-style3 {
        padding-top: 140px;
        margin-top: -90px
    }

    .footer-style3-bottom {
        margin-top: 50px
    }
}

@media screen and (max-width: 767px) {
    .footer-style3 h4 {
        font-size: 16px
    }

    .footer-style3 ul li {
        font-size: 15px
    }
}

.footer-list {
    margin: 0
}

.footer-list li {
    list-style-type: none;
    color: #232323;
    padding: 6px 0
}

.footer-list li:first-child {
    padding-top: 0
}

.footer-list li a {
    font-size: 15px;
    font-weight: 400;
    color: #232323
}

.footer-list li a:before {
    content: '\f105';
    font-weight: 700;
    vertical-align: bottom;
    font-family: Font Awesome\ 5 Free;
    color: #232323;
    padding-right: 8px;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s
}

.footer-list li a:hover {
    color: #29c2c1
}

.footer-list li a:hover:before {
    color: #29c2c1
}

@media screen and (max-width: 767px) {
    .footer-list li a {
        font-size: 14px
    }
}

.footer-list1 {
    margin: 0
}

.footer-list1 li {
    list-style-type: none;
    padding: 12px 0 12px 0;
    font-size: 17px;
    color: #596982
}

.footer-list1 li:first-child {
    padding-top: 0
}

.footer-list1 li:last-child {
    padding-bottom: 0
}

.footer-list1 li a {
    font-weight: 400;
    color: #596982
}

.footer-list1 li a:hover {
    color: #243f67
}

@media screen and (max-width: 1199px) {
    .footer-list1 li {
        font-size: 16px
    }
}

@media screen and (max-width: 767px) {
    .footer-list1 li {
        padding: 8px 0;
        font-size: 15px
    }
}

.footer-list2 {
    margin: 0
}

.footer-list2 li {
    list-style-type: none;
    padding: 5px 0 5px 0;
    font-size: 16px;
    color: #596982
}

.footer-list2 li:first-child {
    padding-top: 0
}

.footer-list2 li a {
    font-weight: 400;
    color: #596982
}

.footer-social-icons ul {
    margin-bottom: 0
}

.footer-social-icons ul li {
    display: inline-block;
    border: 1px solid #29c2c1;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 5px;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: 0.3s
}

.footer-social-icons ul li a {
    color: #29c2c1;
    display: block;
    font-size: 15px;
    height: 35px;
    line-height: 34px;
    text-align: center;
    width: 35px
}

.footer-social-icons ul li:hover {
    background: #29c2c1;
    background: -webkit-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -moz-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: -o-linear-gradient(-45deg, #29c2c1, #0084e9);
    background: linear-gradient(-45deg, #29c2c1, #0084e9)
}

.footer-social-icons ul li:hover i {
    color: #fff
}

.footer-social-icons1 ul {
    margin-bottom: 0
}

.footer-social-icons1 ul li {
    display: inline-block;
    border: 1px solid #243f67;
    border-radius: 50%;
    color: #243f67;
    margin-right: 10px;
    margin-bottom: 5px;
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: 0.3s
}

.footer-social-icons1 ul li a {
    color: #243f67;
    display: block;
    font-size: 15px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    width: 35px
}

.footer-social-icons1 ul li:hover {
    background: #243f67
}

.footer-social-icons1 ul li:hover i {
    color: #fff
}

.footer-social-icons3 {
    margin-bottom: 0
}

.footer-social-icons3 li {
    display: inline-block;
    color: #fff;
    margin-right: 15px
}

.footer-social-icons3 li:last-child {
    margin-right: 0
}

.footer-social-icons3 li a {
    color: #fff;
    display: block;
    font-size: 16px
}

.footer-social-icons3 li a i {
    -webkit-transition-duration: .3s;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: 0.3s
}

.footer-social-icons3 li:hover i {
    color: #fff;
    opacity: 0.65
}

/*# sourceMappingURL=styles.css.map */